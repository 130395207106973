import moment from 'moment';

//Remove a máscara do documento consultado
export const docRemovedMask = (doc) => {
  if (doc === undefined) return;
  let removedMask = doc.replace(/[.-/-]/g, '');
  return removedMask;
};

//Máscara do input valor
export function currencyFormatter(value) {
  if (!Number(value)) return '';
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value / 100);
  return `${amount}`;
}

//Máscara CPF
export const formatCpf = (value) => {
  let cpf = String(value);
  let cpfFormated = cpf.replace(
    /(\d{3})?(\d{3})?(\d{3})?(\d{2})/,
    '$1.$2.$3-$4'
  );
  return cpf === 'null' ? '' : cpfFormated;
};

//Máscara CNPJ
export const formatCnpj = (value) => {
  let cnpj = String(value);
  let cnpjFormated = cnpj.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
  return cnpj === 'null' ? '' : cnpjFormated;
};

//Máscara Currency
export const formatCurrency = (value) => {
  return parseFloat(value ?? 0).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
};

//Remove máscara de valore monetários
export const removeMaskCurrency = (value) =>
  value.replace(/[R$.]/g, '').replace(',', '.').trim();

//Máscara número de inscrição
export const formatRegistrationNumber = (value) =>
  String(value).substring(0, 4) +
  '.' +
  String(value).substring(4, 12) +
  '-' +
  String(value)[12];

export const convertForString = (value) => {
  return value ? 'Sim' : 'Não';
};

// Máscara data
export const formatDate = (value) => {
  if (value) {
    return moment(value).format('MM/yyyy');
  } else {
    return '-';
  }
};

export const formatDateDiff = (value) => {
  if (value) {
    return moment(value).format('DD/MM/yyyy');
  } else {
    return '-';
  }
};

export const formatDateHour = (value) => {
  if (value) {
    return moment(value).format('DD/MM/yyyy HH:MM:SS');
  } else {
    return '-';
  }
};
