import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent
} from '@material-ui/lab';

import * as S from './styles';

const TimelineCard = ({ hour, minute, name, date, cda }) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <S.Hour variant="body2">{`${hour}:${minute}hs`}</S.Hour>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <S.TimelineDot variant="outlined" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <S.Card elevation={3}>
          <S.CardDescription>
            <S.Name>{name}</S.Name>
            <S.Document variant="subtitle2">CDA: {cda}</S.Document>
          </S.CardDescription>
          <S.WrapperDate>
            <S.Day>{date.day} de </S.Day>
            <S.Month>{date.month},</S.Month>
            <S.Year>{`${date.dayWeek} | ${date.year}`}</S.Year>
          </S.WrapperDate>
        </S.Card>
      </TimelineContent>
    </TimelineItem>
  );
};

TimelineCard.propTypes = {
  hour: PropTypes.string,
  minute: PropTypes.string,
  date: PropTypes.object,
  name: PropTypes.string,
  cda: PropTypes.string
};

export default memo(TimelineCard);
