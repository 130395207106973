import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import ImageCardInfoShe from '../../assets/i-banner-user-she.svg';
import ImageCardInfoHe from '../../assets/i-banner-user-he.svg';

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15vh;
  padding: 4.5rem 3rem;
  ${({ theme }) => css`
    background: ${theme.colors.mpColor};
    border-radius: ${theme.border.radius};
  `}
  ${media.lessThan('640px')`
    justify-content: right;
    padding: 1rem 2rem;
    height: 25vh;
    font-size: 12px;
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
  `}
`;
export const Title = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`;
export const Description = styled.p`
  width: 25rem;
  margin-top: 0.3rem;
  ${media.lessThan('640px')`
    width: 90%;
  `}
`;

export const Image = styled.img.attrs((props) => ({
  src: (props.genderType === 'she' && ImageCardInfoShe) || ImageCardInfoHe,
  alt: 'Pessoa no balcão de uma recepção usando computador.',
  title: 'Ilustração da página de cadastro.'
}))`
  margin-bottom: 5rem;
  width: 18vw;
  object-fit: contain;
  ${media.lessThan('935px')`
    display: none;
  `}
  ${media.greaterThan('936px')`
    margin-bottom: 3.3rem;
    width: 28vw;
  `}
  ${media.greaterThan('1024px')`
    width: 24vw;
  `}
  ${media.greaterThan('1280px')`
    width: 22vw;
  `}
  ${media.greaterThan('1400px')`
    width: 19vw;
  `}
  ${media.greaterThan('1680px')`
    width: 17vw;
  `}
  ${media.greaterThan('1920px')`
    width: 16vw;
  `}
  ${media.between('2280px', '2560px')`
    width: 12vw;
  `}
  ${media.greaterThan('2561px')`
    width: 10vw;
  `}
`;
