import * as yup from 'yup';

export const sigInValidateSchema = yup.object().shape({
  document: yup
    .string()
    .test(
      'len',
      'O número do CPF precisa de pelo menos 11 dígitos.',
      (val = '') => {
        const value = val.replace('-', '.').trim().length;
        return value === 14;
      }
    )
    .required('Campo obrigatório!'),
  password: yup.string().required('Campo obrigatório!')
});

export const certificateValidateSchema = yup.object().shape({
  processNumber: yup
    .string()
    .max(30, 'O número do processo deve ter no máximo 30 caracteres.')
    .required('Campo obrigatório!')
});

export const valCertificateValidateSchema = yup.object().shape({
  code: yup
    .string()
    .test('len', 'Informe um número válido.', (val = '') => {
      const value = val.replace('.', '-').trim().length;
      return value === 14;
    })
    .required('Campo obrigatório!')
});

export const forgotPasswordSchema = yup.object().shape({
  cpf: yup
    .string()
    .test('len', 'Informe um número de CPF válido.', (val = '') => {
      const value = val.replace('.', '-').trim().length;
      return value === 14;
    })
    .required('Campo obrigatório!')
});

export const searchValidateSchema = yup.object().shape(
  {
    cda: yup.string().when(['ai', 'document'], {
      is: (ai, document) => !ai && !document,
      then: yup
        .string()
        .test(
          'len',
          'O número de inscrição precisa de pelo menos 13 dígitos.',
          (val = '') => {
            const value = val.replace('-', '.').trim().length;
            return value === 15;
          }
        )
        .required('Campo obrigatório!'),
      otherwise: yup
        .string()
        .test(
          'len',
          'O número de inscrição precisa de pelo menos 13 dígitos.',
          (val) => {
            if (val) {
              const value = val.replace('-', '.').trim().length;
              return value === 15;
            } else {
              return true;
            }
          }
        )
    }),
    ai: yup.string().when(['cda', 'document'], {
      is: (cda, document) => !cda && !document,
      then: yup
        .string()
        .test(
          'len',
          'O número do Auto de Infração precisa de pelo menos 9 dígitos.',
          (val = '') => {
            const value = val.trim().length;
            return value === 9;
          }
        )
        .required('Campo obrigatório!'),
      otherwise: yup
        .string()
        .test(
          'len',
          'O número do Auto de Infração precisa de pelo menos 9 dígitos.',
          (val) => {
            if (val) {
              const value = val.replace('-', '.').trim().length;
              return value === 9;
            } else {
              return true;
            }
          }
        )
    }),
    document: yup.string().when(['cda', 'ai'], {
      is: (cda, ai) => !cda && !ai,
      then: yup
        .string()
        .test(
          'len',
          'O número do documento precisa de pelo menos 8 dígitos.',
          (val = '') => {
            const value = val.replace('-', '.').trim().length;
            return value === 10 || value === 14;
          }
        )
        .required('Campo obrigatório!'),
      otherwise: yup
        .string()
        .test(
          'len',
          'O número do documento precisa de pelo menos 8 dígitos.',
          (val) => {
            if (val) {
              const value = val.replace('-', '.').trim().length;
              return value === 10 || value === 14;
            } else {
              return true;
            }
          }
        )
    })
  },
  [
    ['cda', 'ai'],
    ['cda', 'document'],
    ['ai', 'document', 'cda']
  ]
);

export const changePasswordValidateSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Campo obrigatório!')
    .test(
      'len',
      'A senha deve conter no mínimo 8 caracteres, com letras maiúsculas e minúsculas, números e caracteres especiais.',
      (val = '') => {
        const value = val.trim().length;
        return value >= 8;
      }
    ),
  newPassword: yup
    .string()
    .required('Campo obrigatório!')
    .test(
      'len',
      'A senha deve conter no mínimo 8 caracteres, com letras maiúsculas e minúsculas, números e caracteres especiais.',
      (val = '') => {
        const value = val.trim().length;
        return value >= 8;
      }
    ),
  confirmPassword: yup
    .string()
    .required('Campo obrigatório!')
    .test('passwords-match', 'As senhas não correspondem.', function (value) {
      return this.parent.newPassword === value;
    })
});

export const recoverPasswordValidateSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Campo obrigatório')
    .test(
      'len',
      'A senha deve conter no mínimo 8 caracteres, com letras maiúsculas e minúsculas, números e caracteres especiais.',
      (val = '') => {
        const value = val.trim().length;
        return value >= 8;
      }
    ),
  confirmNewPassword: yup
    .string()
    .required('Campo obrigatório!')
    .test('passwords-match', 'As senhas não correspondem', function (value) {
      return this.parent.newPassword === value;
    })
});
