import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';
import { recoverPasswordValidateSchema } from 'utils/validations/index';

import { CircularProgress } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { MdLockOutline } from 'react-icons/md';
import jwt_decode from 'jwt-decode';
import * as S from './styles';

const FormRecoverPassword = ({ sendRecoverPassword, loading }) => {
  const history = useHistory();
  const getQueryParams = useLocation().search;
  const token = new URLSearchParams(getQueryParams).get('token');
  let decode = jwt_decode(token);
  let exp = decode.exp;
  let currentDate = new Date();
  let isTokenValid = exp * 1000 > currentDate.getTime();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: recoverPasswordValidateSchema,
    onSubmit: async (data) => {
      await sendRecoverPassword({ ...data, token });
      formik.resetForm();
      history.push('/');
    }
  });

  const toHome = () => {
    history.push('/');
  };

  if (isTokenValid) {
    return (
      <S.Wrapper>
        <S.ContentText>
          <S.Title>Recuperar Senha</S.Title>
          <S.Description>
            A senha deve conter no mínimo 8 caracteres, com letras maiúsculas e
            minúsculas, números e caracteres especiais!
          </S.Description>
        </S.ContentText>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.TextField
            type="password"
            placeholder="Digite a nova senha"
            size="small"
            name="newPassword"
            id="newPassword"
            variant="outlined"
            label="Nova Senha"
            value={formik.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {<MdLockOutline />}
                </InputAdornment>
              )
            }}
          />
          <S.TextField
            type="password"
            placeholder="Confirme a nova senha"
            size="small"
            variant="outlined"
            nome="confirmNewPassword"
            id="confirmNewPassword"
            label="Confirmar Nova Senha"
            value={formik.confirmNewPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmNewPassword &&
              Boolean(formik.errors.confirmNewPassword)
            }
            helperText={
              formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {<MdLockOutline />}
                </InputAdornment>
              )
            }}
          />
          <S.SubmitButton disabled={loading} type="submit">
            {loading ? <CircularProgress size={24} /> : 'Confirmar'}
          </S.SubmitButton>
        </S.Form>
      </S.Wrapper>
    );
  } else {
    return (
      <S.Wrapper>
        <S.ContentText>
          <S.Title>Link expirado</S.Title>
          <S.Description>
            Volte ao Consulta MP e clique novamente em esqueceu senha para gerar
            um novo link de recuperação.
          </S.Description>
        </S.ContentText>
        <S.Form>
          <S.SubmitButton onClick={toHome} type="submit">
            Voltar
          </S.SubmitButton>
        </S.Form>
      </S.Wrapper>
    );
  }
};
FormRecoverPassword.propTypes = {
  sendRecoverPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
export default FormRecoverPassword;
