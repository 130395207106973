import PropTypes from 'prop-types';
import MenuDropdown from '../MenuDropdown';

import * as S from './styles';

const Header = ({ signed, signOut, user }) => {
  return (
    <>
      {signed && (
        <S.AppBar position="static">
          <S.Toolbar>
            <S.AppLogo />
            <S.Menu>
              <S.MenuItem href={'/consulta'}>CONSULTAR CDA</S.MenuItem>
              <S.MenuItem href={'/validate'}>VALIDAR CERTIDÃO</S.MenuItem>
              <S.MenuItem href={'/search_history'}>HISTÓRICO</S.MenuItem>
            </S.Menu>
            <MenuDropdown signOut={signOut} user={user} />
          </S.Toolbar>
        </S.AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  signed: PropTypes.bool,
  signOut: PropTypes.func,
  user: PropTypes.string
};

export default Header;
