import { createContext, useReducer } from 'react';
import { alertReducer, alertState } from 'state/alert';

import PropTypes from 'prop-types';

const SnackbarContext = createContext([]);

export const SnackbarProvider = ({ children }) => {
  const alertProvider = useReducer(alertReducer, alertState);

  return (
    <SnackbarContext.Provider value={{ alertProvider }}>
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.object
};

export default SnackbarContext;
