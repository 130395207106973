import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { TextField as input, Button as btn } from '@material-ui/core';
import NotificationAddIcon from '../../assets/notification-add.png';
import NotificationRemoveIcon from '../../assets/notification-remove.png';

import {
  Card as CardWrapper,
  Grid as GridWrapper,
  Typography as Label,
  Typography as Value,
  TableCell
} from '@material-ui/core';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Name = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.font.light};
    margin-bottom: ${theme.spacings.xxsmall};

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xsmall};
    `}
  `}
`;
export const Content = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
  `}
`;

export const Card = styled(CardWrapper)`
  display: flex;
  justify-content: space-between !important;
  position: relative;

  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
  `}

  ${media.lessThan('600px')`
    padding: 0.5rem;
  `}
`;
export const Grid = styled(GridWrapper)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall} !important;

    ${media.lessThan('600px')`
      margin-bottom: ${theme.spacings.xxsmall} !important;
    `}
  `}
`;

export const TypographyLabel = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.black} !important;
    font-family: ${theme.font.family} !important;
    font-weight: ${theme.font.bolder} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;

export const TypographyValue = styled(Value)`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray} !important;
    font-family: ${theme.font.family} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;

export const DebtsTableCell = styled(TableCell)`
  color: #939393 !important;
  width: 15% !important;
`;

export const HeaderTableCell = styled(TableCell)`
  font-weight: bold !important;
  width: '15%' !important;
`;

export const FooterTableCell = styled(TableCell)`
  font-weight: bold !important;
  font-size: 0.9rem !important;
  color: #939393 !important;
`;

export const TributaryAlertGrid = styled.div`
  color: #939393;
  width: 100%;
  display: flex;
  flex-direction: column;

  font-size: 12px;

  margin-top: 30px;

  * {
    text-align: right;
  }
`;
// MODAL
export const DialogContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DialogTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
  margin-bottom: 1.8rem !important;
  width: 18rem;
  text-align: center;
`;

export const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const DialogInput = styled(input)`
  .MuiOutlinedInput-input {
    width: 18.7rem;
  }
  svg {
    fill: gray;
  }
`;
export const DialogSubmit = styled(btn)`
  ${({ theme }) => css`
    background: linear-gradient(
      90deg,
      ${theme.colors.gradientBegin} 0%,
      ${theme.colors.gradientEnd} 100%
    );
    color: ${theme.colors.white} !important;
  `}
  margin-top: 1rem !important;
  width: 22rem;
  border: none;
  border-radius: 0.3rem;
`;
export const NotificationAddButton = styled.img.attrs((props) => ({
  src: props.Img || NotificationAddIcon,
  alt: 'Clique para adicionar um alerta sobre atualizações da CDA.',
  title: 'Clique para adicionar um alerta sobre atualizações da CDA.'
}))`
  width: 1.8rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  margin: 10px;
  cursor: pointer;
`;
export const NotificationRemoveButton = styled.img.attrs((props) => ({
  src: props.Img || NotificationRemoveIcon,
  alt: 'Clique para remover o alerta sobre atualizações da CDA.',
  title: 'Clique para remover o alerta sobre atualizações da CDA.'
}))`
  width: 1.8rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  margin: 10px;
  cursor: pointer;
`;

export const loadingAddAndRemove = styled.div`
  width: 1.8rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  margin: 10px;
  pointer-events: none;
`;
