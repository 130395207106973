import styled, { css } from 'styled-components';

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  border-radius: 0.3rem;
  min-width: 9.3rem;
  box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover {
    ${DropdownContent} {
      display: block;
    }
  }
`;

export const WrapperMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const Menu = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    margin-right: ${theme.spacings.xxsmall};
  `}
`;

export const MenuItems = styled.a`
  padding: 0.8rem 1.2rem;
  text-decoration: none;
  display: block;
  ${({ theme }) => css`
    color: ${theme.colors.gray};
  `}

  &:hover {
    background-color: #f1f1f1;
  }
`;
