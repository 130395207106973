import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import { valCertificateValidateSchema } from 'utils/validations';

import { CircularProgress } from '@material-ui/core';
import * as S from './styles';

const FormValidate = ({ getValidate, loading, showMessage }) => {
  const formik = useFormik({
    initialValues: { code: '' },
    validationSchema: valCertificateValidateSchema,
    onSubmit: async ({ code }) => {
      await getValidate(code);
      formik.resetForm();
    }
  });

  return (
    <>
      <S.FormWrapper>
        <form onSubmit={formik.handleSubmit}>
          <S.InputMask
            mask={'******-****-**'}
            value={formik.values.code.toUpperCase()}
            onChange={formik.handleChange}
            err={
              (formik.touched.code && !!formik.errors.code && 'true') || 'false'
            }
            name="code"
            type="text"
            id="code"
            placeholder="Insira o código de validação contido na certidão"
            size="small"
            variant="outlined"
          />
          <S.Button type="submit">
            {loading ? <CircularProgress size={24} /> : 'VALIDAR CERTIDÃO'}
          </S.Button>
        </form>
        {showMessage && (
          <S.ResultNoValidate>
            O código de validação informado da certidão não foi localizado.
          </S.ResultNoValidate>
        )}
      </S.FormWrapper>
    </>
  );
};

FormValidate.propTypes = {
  getValidate: PropTypes.func,
  loading: PropTypes.bool,
  err: PropTypes.string,
  showMessage: PropTypes.bool
};

export default FormValidate;
