import { useCallback } from 'react';
import { useState } from 'react';
import { search } from 'services/service';
import { docRemovedMask } from 'utils/formatMask';

import { useSnackbar } from 'hooks/useSnackbar';

export function useSearch() {
  const [data, setData] = useState({});
  const [cda, setCda] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTributary, setIsTributary] = useState(false);
  const [cdaList, setCdaList] = useState([]);
  const [newSearch, setNewSearch] = useState(false);
  const [corporate, setCorporate] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  const getSearch = useCallback(
    async (cda, ai, document) => {
      try {
        setLoading(true);
        const cdaRemovedMask = docRemovedMask(cda);
        const aiRemovedMask = docRemovedMask(ai);
        const documentRemovedMask = docRemovedMask(document);
        const response = await search(
          cdaRemovedMask,
          aiRemovedMask,
          documentRemovedMask
        );
        setData(response.data.data);
        setCorporate(response.data.resume);
        setIsTributary(response.data.data.is_tributary);
        setCda(cda);
        setCdaList(response.data.data);

        if (response.data.tri_off && response.data.not_tri_off) {
          alertDispatch({
            type: 'SHOW_ALERT',
            payload: {
              message: 'Erro ao consultar!',
              severity: 'error'
            }
          });
        }

        if (response.data.tri_off && !response.data.not_tri_off) {
          alertDispatch({
            type: 'SHOW_ALERT',
            payload: {
              message: 'Não foi possível buscar as dívidas tributárias!',
              severity: 'error'
            }
          });
        }

        if (!response.data.tri_off && response.data.not_tri_off) {
          alertDispatch({
            type: 'SHOW_ALERT',
            payload: {
              message: 'Não foi possível buscar as dívidas não tributárias!',
              severity: 'error'
            }
          });
        }

        if (!response.data.tri_off && !response.data.not_tri_off) {
          alertDispatch({
            type: 'SHOW_ALERT',
            payload: { message: 'Consulta realizada com sucesso!' }
          });
        }
      } catch (e) {
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: { message: 'Erro ao consultar!', severity: 'error' }
        });
      } finally {
        setNewSearch(true);
        setLoading(false);
      }
    },
    [alertDispatch]
  );

  return {
    cda,
    data,
    getSearch,
    loading,
    setLoading,
    setData,
    alertDispatch,
    isTributary,
    cdaList,
    setCdaList,
    corporate,
    newSearch,
    setNewSearch
  };
}
