import { Button, Icon, AppBar } from '@material-ui/core';
import media from 'styled-media-query';

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const OpenDialog = styled(Button)`
  margin-top: 2rem !important;
  width: 100%;
  border: none;
  font-size: 1rem !important;
  border-radius: 0.3rem;

  ${({ theme }) => css`
    background: linear-gradient(
      90deg,
      ${theme.colors.gradientBegin} 0%,
      ${theme.colors.gradientEnd} 100%
    );
    color: ${theme.colors.white} !important;
  `};

  ${(props) =>
    props.type === 'login' &&
    css`
      background: #ebebeb !important;
      color: #009b89 !important;
      font-size: 12px !important;
    `}

  ${(props) =>
    props.type === 'social' &&
    css`
      color: #4ebbb9 !important;
      background: transparent !important;
      font-size: 1.4rem !important;
      width: auto !important;
      padding: 0 !important;
    `}

    ${(props) =>
    props.type === 'remove' &&
    css`
      width: auto !important;
      margin: 0.5rem !important;
      max-height: 2.5rem;
      ${({ theme }) => css`
        background: ${theme.colors.removeColor} !important;
        color: ${theme.colors.white} !important;
      `}
      ${media.lessThan('1024px')`
  height: 2.5rem;
  font-size: 1rem !important;
    ${({ theme }) => css`
      margin-top: ${theme.spacings.xxsmall} !important;
    `}
  `}
    `}
  ${media.between('1025px', '1366px')`
    font-size: 1rem !important;
  `}

  ${media.lessThan('426px')`
      width: 21.3rem;
  `};
  ${media.lessThan('376px')`
      width: 18.7rem;
  `};
  ${media.lessThan('320px')`
    width: 16rem;
  `};
`;

export const ToolbarIcon = styled(Icon)`
  color: #fafafa;
`;

export const AppBarModal = styled(AppBar)`
  background: #4ebbb9 !important;
`;
