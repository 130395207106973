import axios from './api';
const PATH_API = process.env.REACT_APP_PUBLIC_API_BASE_URL;

export const login = (userLogin) => {
  return axios.post(`${PATH_API}/auth/login`, userLogin);
};

export const logout = () => {
  return axios.delete(`${PATH_API}/auth/logout`);
};

export const search = (cda, ia, document) => {
  return axios.get(
    `${PATH_API}/search_inscriptions?registration_number=${cda}&infraction_notice=${ia}&document=${document}`
  );
};

export const searchAlert = (userId, cda, ia, document) => {
  return axios.get(
    `${PATH_API}/search_alerts?user_id=${userId}&registration_number=${cda}&infraction_notice=${ia}&document=${document.trim()}`
  );
};

export const history = (page) => {
  return axios.get(`${PATH_API}/history?page=${page}`);
};

export const generate = (cda, processNumber, isTributary, userId) => {
  return axios.get(
    `${PATH_API}/certificate?user_id=${userId}&registration_number=${cda}&is_tributary=${isTributary}&judicially_process_number=${processNumber}`
  );
};

export const certificate = (data) => {
  return axios.get(`${PATH_API}/certificate/search?file=${data}`, {
    responseType: 'arraybuffer'
  });
};

export const validate = (code) => {
  return axios.get(`${PATH_API}/certificate/validate?code=${code}`);
};

export const forgotPassword = (data) => {
  return axios.post(`${PATH_API}/auth/forgot_password`, {
    cpf: data.cpf,
    system: data.system
  });
};

export const recoverPassword = (data) => {
  return axios.post(`${PATH_API}/auth/new_password`, {
    password: data.newPassword,
    token: data.token
  });
};

export const changePassword = (values) => {
  return axios.put(`${PATH_API}/auth/change_password`, {
    old_password: values.currentPassword,
    new_password: values.confirmPassword
  });
};

export const addNotification = (data) => {
  return axios.post(`${PATH_API}/notification/new_notification`, {
    user_id: data.userId,
    cda: data.cda,
    document: data.document,
    ai: data.ai,
    current_step: data.current_step,
    registry_charged: data.registry_charged,
    judicially_charged: data.judicially_charged,
    sequence: data.sequence,
    quantity_of: data.quantity_of,
    quantity_of_paid: data.quantity_of_paid,
    start_date: data.start_date,
    name: data.name,
    full_document: data.fullDocument
  });
};

export const removeNotification = (data) => {
  return axios.put(`${PATH_API}/notification/delete_notification`, {
    user_id: data.userId,
    cda: data.cda
  });
};

export const existsNotification = (data) => {
  let cda = '';
  if (typeof data.cda === 'string' && data.cda.length > 0) {
    cda = data.cda.replace(/[.-]/g, '');
  } else {
    cda = data.cda;
  }
  return axios.get(
    `${PATH_API}/notification/exists_notification?user_id=${data.userId}&cda=${cda}`
  );
};
