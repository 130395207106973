import { useState, useEffect, useCallback } from 'react';

export function useViewport() {
  const [viewport, setViewport] = useState(window.innerWidth);

  const updateDimensions = useCallback(() => {
    setViewport(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  return { viewport };
}
