import { useRecoverPassword } from 'hooks/useRecoverPassword';
import FormRecoverPassword from './Form';

const RecoverPassword = () => {
  const { sendRecoverPassword, loading } = useRecoverPassword();

  return (
    <FormRecoverPassword
      sendRecoverPassword={sendRecoverPassword}
      loading={loading}
    />
  );
};
export default RecoverPassword;
