import { Typography } from '@material-ui/core';
import * as S from './styles';

const SkeletonLoading = () => {
  return (
    <S.Wrapper>
      <Typography variant="h3">
        <S.SkeletonTitle animation="wave" />
      </Typography>

      <S.Content>
        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>
        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>
        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>

        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>
        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>

        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>
        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>

        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>
        <S.GroupContent>
          <S.SkeletonLabel animation="wave" />
          <S.SkeletonInput animation="wave" />
        </S.GroupContent>
      </S.Content>
      <Typography variant="h2">
        <S.SkeletonButton animation="wave" />
      </Typography>
    </S.Wrapper>
  );
};
export default SkeletonLoading;
