// import * as S from './styles';
// import FormSearch from 'components/FormSearch';
import { useValidateCertificate } from 'hooks/use-validate-certificate';

import FormValidate from 'components/FormValidate';
import CardValidate from 'components/CardValidate';
import CardInfo from 'components/CardInfo';

import SkeletonLoading from 'components/SkeletonLoading/Search';
import * as S from './styles';

const ValidateCertificate = () => {
  const { getValidate, loading, data, code, err, showMessage } =
    useValidateCertificate();
  return (
    <S.Wrapper>
      <S.Content>
        <CardInfo
          gender="she"
          text="Use o formulário abaixo para validar uma certidão."
        />
        <FormValidate
          getValidate={getValidate}
          loading={loading}
          showMessage={showMessage}
        />
        {loading && <SkeletonLoading />}
        {err.message === 'Certidão inválida' && !loading && (
          <S.ResultNoValidate>
            Não foi possível validar a certidão.
          </S.ResultNoValidate>
        )}
        {!data && !loading && (
          <S.ResultNoValidate>Não foi validar a certidão.</S.ResultNoValidate>
        )}
        {!!data.document && !loading && err.message != 'Certidão inválida' && (
          <CardValidate data={data} code={code} />
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default ValidateCertificate;
