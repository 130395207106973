import { useMemo, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { Grid } from '@material-ui/core';
import { useNotificationCda } from 'hooks/use-notification/useNotification';
import Dialog from 'components/Dialog';
import moment from 'moment';
import { useSearch } from 'hooks/use-search';
import {
  formatCpf,
  formatCnpj,
  formatRegistrationNumber
} from 'utils/formatMask';

const CardAlert = ({ viewClick, data }) => {
  const { getSearch, cdaList } = useSearch();
  const { removeNotificationCda } = useNotificationCda(data);
  const masks = useMemo(() => {
    const { full_document, cda } = data;
    return {
      document:
        String(full_document).length == 11
          ? formatCpf(full_document)
          : formatCnpj(full_document),
      registrationNumber: formatRegistrationNumber(cda),
      documentType: String(full_document).length == 11 ? 'CPF:' : 'CNPJ:'
    };
  }, [data]);

  async function viewCallback() {
    await getSearch(data.cda, '', '');
  }
  useEffect(() => {
    if (cdaList.length == 1) {
      viewClick(cdaList[0]);
    }
  }, [cdaList]);

  function formatarData(data) {
    var dataMoment = moment(data);
    if (dataMoment.isValid()) {
      return dataMoment.format('DD/MM/YYYY');
    }
    return data;
  }
  var dataInclusaoFormat = data.created_at;
  var dataAtualizadaFormat = data.updated_at;
  var dataInclusao = formatarData(dataInclusaoFormat);
  var dataAtualizada = formatarData(dataAtualizadaFormat);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Card>
          <Grid container justifyContent="space-evenly">
            <S.Grid>
              <S.TypographyLabel variant="subtitle1">
                Nº Inscrição:
              </S.TypographyLabel>
              <S.TypographyValue variant="body2">
                {masks.registrationNumber}
              </S.TypographyValue>
            </S.Grid>
            <S.Grid>
              <S.TypographyLabel variant="subtitle1">
                {masks.documentType}
              </S.TypographyLabel>
              <S.TypographyValue variant="body2">
                {masks.document}
              </S.TypographyValue>
            </S.Grid>
            <S.Grid>
              <S.TypographyLabel variant="subtitle1">Nome:</S.TypographyLabel>
              <S.TypographyValue variant="body2">{data.name}</S.TypographyValue>
            </S.Grid>
            <S.Grid>
              <S.TypographyLabel variant="subtitle1">
                Auto de Infração:
              </S.TypographyLabel>
              <S.TypographyValue variant="body2">
                {String(data.ai) == '0' || !data.ai ? '-' : data.ai}
              </S.TypographyValue>
            </S.Grid>
            <S.Grid>
              <S.TypographyLabel variant="subtitle1">
                Data de inclusão:
              </S.TypographyLabel>
              <S.TypographyValue variant="body2">
                {dataInclusao}
              </S.TypographyValue>
            </S.Grid>
            <S.Grid>
              <S.TypographyLabel variant="subtitle1">
                Última atualização:
              </S.TypographyLabel>
              <S.TypographyValue variant="body2">
                {dataAtualizada}
              </S.TypographyValue>
            </S.Grid>
            <S.Grid style={{ display: 'flex', flexDirection: 'row' }}>
              <S.Button
                type="submit"
                label="Senha Atual"
                onClick={() => {
                  viewCallback();
                }}
              >
                {'Ver'}
              </S.Button>
              <Dialog btnText="REMOVER" type="remove">
                <S.DialogContainer>
                  <S.DialogTitle> Confirmar remoção </S.DialogTitle>
                  <S.DialogSubTitle>
                    Deseja realmente remover o alerta de atualização cadastrado
                    para a CDA de Nº {data.cda} ?
                  </S.DialogSubTitle>
                  <S.DialogForm>
                    <S.ButtonRemove type="submit" onClick={() => {}}>
                      {'NÃO'}
                    </S.ButtonRemove>
                    <S.Button
                      onClick={() => {
                        removeNotificationCda(data);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      }}
                    >
                      {'SIM'}
                    </S.Button>
                  </S.DialogForm>
                </S.DialogContainer>
              </Dialog>
            </S.Grid>
          </Grid>
        </S.Card>
      </S.Content>
    </S.Wrapper>
  );
};

CardAlert.propTypes = {
  data: PropTypes.object,
  viewClick: PropTypes.func,
  registration_number: PropTypes.number,
  document: PropTypes.number,
  user_id: PropTypes.string,
  cdaList: PropTypes.func
};

export default memo(CardAlert);
