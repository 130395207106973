import styled, { css } from 'styled-components';
import { Timeline as Line, Alert } from '@material-ui/lab';

import media from 'styled-media-query';

export const Timeline = styled(Line).attrs((props) => ({
  align: props.$width > 768 ? 'alternate' : 'left'
}))`
  margin-top: 4rem;
  ${media.lessThan('768px')`
    .MuiTimelineOppositeContent-root {
      display: none;
    }
`}
`;

export const TextEndHistory = styled(Alert)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;

  ${({ theme }) => css`
    font-family: ${theme.font.family} !important;
    color: ${theme.colors.primary} !important;
    margin-top: ${theme.spacings.xsmall};

    .MuiSvgIcon-fontSizeInherit {
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.medium};
    }

    .MuiAlert-message {
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;
