import { useSearchHistory } from 'hooks/useSearchHistory';
import { useViewport } from 'hooks/useViewport';

import TimelineCard from 'components/TimelineCard';
import CardInfo from 'components/CardInfo';
import Loading from 'components/SkeletonLoading/SearchHistory';

import * as S from './styles';

const SearchHistory = () => {
  const { data, loader, pageSize } = useSearchHistory();
  const { viewport } = useViewport();

  return (
    <>
      <CardInfo text="Aqui você tem acesso ao seu histórico de consulta." />
      <S.Timeline $width={viewport}>
        {data &&
          data.map((item) => (
            <TimelineCard
              key={item.id}
              hour={item.hour}
              minute={item.minute}
              date={{
                day: item.day,
                month: item.month_name,
                dayWeek: item.day_week,
                year: item.year
              }}
              name={item.name}
              cda={item.registration_number}
            />
          ))}
        <div ref={loader} />
      </S.Timeline>
      {pageSize !== 0 && <Loading />}
      {pageSize === 0 && (
        <S.TextEndHistory severity="success">
          Histórico completo
        </S.TextEndHistory>
      )}
    </>
  );
};

export default SearchHistory;
