import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { IMaskInput as MaskedInput } from 'react-imask';

import { TextField as input, Button as btn } from '@material-ui/core';

export const FormWrapper = styled.div`
  width: 100%;
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
  `}
  > form {
    display: flex;
    justify-content: space-between;
    ${media.lessThan('1024px')`
      display: flex;
      flex-direction: column;
    `}
  }
`;

export const TextField = styled(input)`
  width: 80%;

  ${({ theme }) => css`
    margin-right: ${theme.spacings.xxsmall} !important;
  `}

  ${media.lessThan('1024px')`
    width: 100%;
  `}
`;

export const InputMask = styled(MaskedInput).attrs(() => {})`
  ${(props) =>
    props.err === 'true' &&
    css`
      border-color: #f44336 !important;
      :focus {
        outline: none !important;
        border: 2px solid #f44336;
      }
    `}
  width: 80%;
  border: 0;
  cursor: text;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.00938em;
  color: currentColor;
  background: transparent;
  box-sizing: content-box;
  padding-left: 8px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  ${({ theme }) => css`
    margin-right: ${theme.spacings.xxsmall} !important;
  `}

  ${media.lessThan('1024px')`
    width: 100%;
  `}
`;

export const ErrorText = styled.span`
  color: #f44336;
  margin-top: 4px;
  font-size: 0.75rem;
`;

export const Button = styled(btn)`
  width: 20%;
  max-height: 2.5rem;
  ${({ theme }) => css`
    background: linear-gradient(
      90deg,
      ${theme.colors.gradientBegin} 0%,
      ${theme.colors.gradientEnd} 100%
    );
    color: ${theme.colors.white} !important;
  `}

  ${media.lessThan('1024px')`
  height: 2.5rem;
  font-size: 1rem !important;
    width: 100%;
    ${({ theme }) => css`
      margin-top: ${theme.spacings.xxsmall} !important;
    `}
  `}
  ${media.between('1025px', '1366px')`
    font-size: .7rem !important;
  `}
`;
export const ResultNoValidate = styled.h3`
  margin-top: 2rem;
  text-align: center;
  color: #939393;
`;
