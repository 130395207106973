import Form from './Form';
import { useChangePassword } from 'hooks/use-change-password';
import * as S from './styles';

const ChangePassword = () => {
  const { newChangePassword, loading } = useChangePassword();

  return (
    <S.Wrapper>
      <S.ContentText>
        <S.Title></S.Title>
        <S.Description>
          A senha deve conter no mínimo 8 caracteres, com letras maiúsculas e
          minúsculas, números e caracteres especiais!
        </S.Description>
      </S.ContentText>
      <Form newChangePassword={newChangePassword} loading={loading} />
    </S.Wrapper>
  );
};
export default ChangePassword;
