import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/useAuth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { signed } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        signed ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object
};

export default PrivateRoute;
