import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import { sigInValidateSchema } from 'utils/validations';
import NumberFormat from 'react-number-format';

import InputAdornment from '@material-ui/core/InputAdornment';
import { CircularProgress } from '@material-ui/core';
import { MdPersonOutline, MdLockOutline } from 'react-icons/md';

import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';

import * as S from './styles';

const recaptchaRef = React.createRef();

const Form = ({ signIn, loadingBtnLogin }) => {
  const formik = useFormik({
    initialValues: { document: '', password: '' },
    validationSchema: sigInValidateSchema,
    onSubmit: async (data) => {
      await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      await signIn(data);
      history.push('/search');
      formik.resetForm();
    }
  });

  return (
    <S.Form onSubmit={formik.handleSubmit}>
      <NumberFormat
        customInput={S.TextField}
        format={'###.###.###-##'}
        value={formik.values.document}
        onChange={formik.handleChange}
        error={formik.touched.document && !!formik.errors.document}
        helperText={formik.touched.document && formik.errors.document}
        label="CPF"
        name="document"
        id="document"
        placeholder="Digite o CPF"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {<MdPersonOutline />}
            </InputAdornment>
          )
        }}
      />
      <S.TextField
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && !!formik.errors.password}
        helperText={formik.touched.password && formik.errors.password}
        type="password"
        label="Senha"
        name="password"
        id="password"
        placeholder="Digite sua senha"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {<MdLockOutline />}
            </InputAdornment>
          )
        }}
      />
      <S.Button
        disabled={!formik.values.document || !formik.values.password}
        type="submit"
      >
        {loadingBtnLogin ? <CircularProgress size={24} /> : 'ENTRAR'}
      </S.Button>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
        ref={recaptchaRef}
        size="invisible"
      />
    </S.Form>
  );
};

Form.propTypes = {
  signIn: PropTypes.func,
  loadingBtnLogin: PropTypes.bool
};

export default Form;
