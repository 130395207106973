import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { useFormik } from 'formik';
import { searchValidateSchema } from 'utils/validations';

import { CircularProgress } from '@material-ui/core';
import * as S from './styles';

const FormSearch = ({ setClicked, setCdaList, getSearch, loading }) => {
  const formik = useFormik({
    initialValues: { cda: '', ai: '', document: '' },
    validationSchema: searchValidateSchema,
    onSubmit: async ({ cda, ai, document }) => {
      setClicked(false);
      setCdaList([]);
      await getSearch(cda, ai, document);
      formik.resetForm();
    }
  });

  const maskBuilder = (value) => {
    if (value.replace('-', '').replace('.', '').trim().length < 10) {
      return '##.###.####';
    } else {
      return '###.###.###-##';
    }
  };

  return (
    <S.FormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <NumberFormat
          customInput={S.TextField}
          format={'####.########-#'}
          value={formik.values.cda}
          onChange={formik.handleChange}
          error={formik.touched.cda && !!formik.errors.cda}
          helperText={formik.touched.cda && formik.errors.cda}
          name="cda"
          id="cda"
          placeholder="Insira o número da CDA"
          size="small"
          variant="outlined"
        />
        <NumberFormat
          customInput={S.TextField}
          format={'#########'}
          value={formik.values.ai}
          onChange={formik.handleChange}
          error={formik.touched.ai && !!formik.errors.ai}
          helperText={formik.touched.ai && formik.errors.ai}
          name="ai"
          id="ai"
          placeholder="Insira o número do AI"
          size="small"
          variant="outlined"
        />
        <NumberFormat
          customInput={S.TextField}
          format={maskBuilder(formik.values.document)}
          value={formik.values.document}
          onChange={formik.handleChange}
          error={formik.touched.document && !!formik.errors.document}
          helperText={formik.touched.document && formik.errors.document}
          name="document"
          id="document"
          placeholder="Insira o CPF ou CNPJ Base"
          size="small"
          variant="outlined"
        />
        <S.Button type="submit">
          {loading ? <CircularProgress size={24} /> : 'REQUISITAR INFORMAÇÕES'}
        </S.Button>
      </form>
    </S.FormWrapper>
  );
};

FormSearch.propTypes = {
  getSearch: PropTypes.func,
  loading: PropTypes.bool,
  setClicked: PropTypes.func,
  setCdaList: PropTypes.func
};

export default FormSearch;
