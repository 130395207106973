import { useCallback } from 'react';
import { useState } from 'react';
import { validate } from 'services/service';
import { docRemovedMask } from 'utils/formatMask';

import { useSnackbar } from 'hooks/useSnackbar';

export const useValidateCertificate = () => {
  const [data, setData] = useState({});
  const [err, setMessage] = useState({});
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  const getValidate = useCallback(
    async (code) => {
      try {
        setShowMessage(false);
        setLoading(true);
        const unmaskedCode = docRemovedMask(code);
        const response = await validate(unmaskedCode);

        setData(response.data.data);
        setMessage({});
        setCode(code);
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: { message: 'Validação realizada com sucesso!' }
        });
      } catch (e) {
        setMessage(e.response.data);
        setShowMessage(true);
        setData({});
      } finally {
        setLoading(false);
      }
    },
    [alertDispatch]
  );
  return {
    data,
    code,
    getValidate,
    loading,
    err,
    showMessage
  };
};
