import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 80%;
  margin: 4rem auto;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #fafafa;
`;

export const Loader = styled.span`
  border-radius: 50%;
  position: relative;
  margin: 50px;
  display: inline-block;
  height: 0px;
  width: 0px;

  span {
    position: absolute;
    display: block;
    background: #333;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    top: -20px;
    perspective: 100000px;
  }

  span:nth-child(1) {
    left: 60px;
    animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation-delay: 0s;
    background: #1cceb9;
  }

  span:nth-child(2) {
    left: 20px;
    animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation-delay: 0.02s;
    background: #009b89;
  }

  span:nth-child(3) {
    left: -20px;
    animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation-delay: 0.04s;
    background: #008172;
  }

  span:nth-child(4) {
    left: -60px;
    animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation-delay: 0.06s;
    background: #004840;
  }

  @keyframes bounce2 {
    0%,
    75%,
    100% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(-30px);
    }
  }
`;
