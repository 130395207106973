import PropTypes from 'prop-types';

import { MdAccountCircle } from 'react-icons/md';
import { Icon } from '@material-ui/core';

import * as S from './styles';

const MenuDropdown = ({ signOut, user }) => {
  const handleClose = () => {
    signOut();
  };

  return (
    <S.Dropdown>
      <S.WrapperMenu>
        <S.Menu>Olá {user}</S.Menu>
        <Icon aria-label="Ícone de usuário logado">
          <MdAccountCircle />
        </Icon>
      </S.WrapperMenu>
      <S.DropdownContent>
        <S.MenuItems href={'/alertas_cadastrados'}>
          Alertas cadastrados
        </S.MenuItems>
        <S.MenuItems href={'/change_password'}>Alterar Senha</S.MenuItems>
        <S.MenuItems onClick={handleClose}>Sair</S.MenuItems>
      </S.DropdownContent>
    </S.Dropdown>
  );
};

MenuDropdown.propTypes = {
  signOut: PropTypes.func.isRequired,
  user: PropTypes.string
};

export default MenuDropdown;
