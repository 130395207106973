import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { Button, TextField as Input } from '@material-ui/core';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 800px;
`;

export const ContentText = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: ${theme.font.bold};
    margin-bottom: 0.5rem;
  `}
`;

export const Description = styled.p`
  width: 30rem;
  font-size: 1rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${media.lessThan('640px')`
    width: 100%;
  `};
  ${media.greaterThan('641px')`
    width: 100%;
  `};
  ${media.greaterThan('800px')`
    width: 70%;
  `};
  ${media.greaterThan('1024px')`
    width: 50%;
  `};
  ${media.greaterThan('1400px')`
    width: 40%;
  `};
  ${media.greaterThan('1600px')`
    width: 35%;
  `};
`;

export const TextField = styled(Input)`
  .MuiOutlinedInput-input {
    width: 18rem;
  }

  svg {
    fill: gray;
  }
`;

export const SubmitButton = styled(Button)`
  ${({ theme }) => css`
    background: ${theme.colors.mpColor} !important;
    color: ${theme.colors.white} !important;
  `}
  height: 3rem;
  border: none;
  border-radius: 0.3rem;
`;
