import { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import { changePasswordValidateSchema } from 'utils/validations/index';

import {
  InputAdornment,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { MdLockOutline } from 'react-icons/md';

import { Form, TextField, SubmitButton } from './styles';

const FormChangePassword = ({ newChangePassword, loading }) => {
  const [values, setValues] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const handleClickIconButton = (name) => {
    setValues({ ...values, [name]: !values[name] });
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: changePasswordValidateSchema,
    onSubmit: (values) => {
      newChangePassword(values);
      formik.resetForm();
    }
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextField
        placeholder="Digite a senha atual"
        size="small"
        name="currentPassword"
        id="currentPassword"
        variant="outlined"
        label="Senha Atual"
        value={formik.values.currentPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.currentPassword && !!formik.errors.currentPassword
        }
        helperText={
          formik.touched.currentPassword && formik.errors.currentPassword
        }
        type={values.currentPassword ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {<MdLockOutline />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle current password visibility"
                onClick={() => handleClickIconButton('currentPassword')}
              >
                {values.currentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        placeholder="Digite a nova senha"
        size="small"
        name="newPassword"
        id="newPassword"
        variant="outlined"
        label="Nova Senha"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        error={formik.touched.newPassword && !!formik.errors.newPassword}
        helperText={formik.touched.newPassword && formik.errors.newPassword}
        type={values.newPassword ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {<MdLockOutline />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle new password visibility"
                onClick={() => handleClickIconButton('newPassword')}
              >
                {values.newPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        placeholder="Confirme a nova senha"
        size="small"
        variant="outlined"
        nome="confirmPassword"
        id="confirmPassword"
        label="Confirmar Nova Senha"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmPassword && !!formik.errors.confirmPassword
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        type={values.confirmPassword ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {<MdLockOutline />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={() => handleClickIconButton('confirmPassword')}
              >
                {values.confirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <SubmitButton type="submit">
        {loading ? <CircularProgress size={24} /> : 'Enviar'}
      </SubmitButton>
    </Form>
  );
};

FormChangePassword.propTypes = {
  newChangePassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default FormChangePassword;
