import { createContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';

import { useSnackbar } from 'hooks/useSnackbar';

import api from 'services/api';
import { docRemovedMask } from 'utils/formatMask';

import { login, logout } from 'services/service';

import * as S from '../styles';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingBtnLogin, setLoadingBtnLogin] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  useEffect(() => {
    async function loadStoragedData() {
      const storagedUser = localStorage.getItem('user');
      const storagedFullName = localStorage.getItem('full_name');
      const storageId = localStorage.getItem('id');
      const storagedToken = localStorage.getItem('token');

      if (storageId && storagedFullName && storagedUser && storagedToken) {
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;

        setId(storageId);
        setFullName(storagedFullName);
        setUser(JSON.parse(storagedUser));
        setLoading(false);
      }
      setLoading(false);
    }

    loadStoragedData();
  }, []);

  const signIn = useCallback(
    async ({ document, password }) => {
      const doc = docRemovedMask(document);
      const userLogin = { cpf: doc, password, system: 4 };

      try {
        setLoadingBtnLogin(true);
        const response = await login(userLogin);

        localStorage.setItem('token', JSON.stringify(response.data.data.token));
        let decode = jwt_decode(response.data.data.token);
        let { id, first_name, last_name } = decode.data;

        localStorage.setItem('user', JSON.stringify(first_name));
        localStorage.setItem('full_name', first_name + ' ' + last_name);
        localStorage.setItem('id', id);

        setUser(first_name);
        setFullName(first_name + ' ' + last_name);
        setId(id);

        alertDispatch({
          type: 'SHOW_ALERT',
          payload: { message: 'Login realizado com sucesso!' }
        });
      } catch (err) {
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: err.response.data.message || `Erro ao realizar o login`,
            severity: 'error'
          }
        });
      } finally {
        setLoadingBtnLogin(false);
      }
    },
    [alertDispatch]
  );

  const signOut = useCallback(async () => {
    try {
      const result = await logout();
      setUser(null);
      api.defaults.headers.Authorization = null;
      localStorage.clear();
      alertDispatch({
        type: 'SHOW_ALERT',
        payload: { message: result.data.message }
      });
    } catch (error) {
      alertDispatch({
        type: 'SHOW_ALERT',
        payload: { message: 'Erro ao fazer logout!', severity: 'error' }
      });
    }
  }, [alertDispatch]);

  if (loading) {
    return (
      <S.LoaderWrapper>
        <S.Loader>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </S.Loader>
      </S.LoaderWrapper>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        id,
        fullName,
        signIn,
        signOut,
        loadingBtnLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.object
};

export default AuthContext;
