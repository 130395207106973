import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_BASE_URL
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const code = error && error.response ? error.response.status : 0;
    if (code === 401 || code === 403) {
      window.location.href = '/';
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default api;
