import { Button as btn, TextField as input } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const DialogInput = styled(input)`
  .MuiOutlinedInput-input {
    width: 18.7rem;
  }
  svg {
    fill: gray;
  }
`;

export const DialogSubmit = styled(btn)`
  ${({ theme }) => css`
    background: ${theme.colors.mpColor} !important;
    color: ${theme.colors.white} !important;
  `}
  margin-top: 1rem !important;
  width: 22rem;
  border: none;
  border-radius: 0.3rem;
`;
