import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { searchAlert } from 'services/service';
import { docRemovedMask } from 'utils/formatMask';
import { useAuth } from 'hooks/useAuth';

import { useSnackbar } from 'hooks/useSnackbar';

export function useAlert() {
  const { id } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSearch, setNewSearch] = useState(false);
  const [cdaList, setCdaList] = useState([]);
  const [corporate, setCorporate] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  const getAlert = useCallback(
    async (cda, ai, document) => {
      setLoading(false);
      asyncGetAlert(cda, ai, document);
    },
    [alertDispatch]
  );
  async function asyncGetAlert(cda, ai, document) {
    try {
      setLoading(true);
      const cdaRemovedMask = docRemovedMask(cda);
      const aiRemovedMask = docRemovedMask(ai);
      const documentRemovedMask = docRemovedMask(document);
      const response = await searchAlert(
        id,
        cdaRemovedMask,
        aiRemovedMask,
        documentRemovedMask
      );
      setData(response.data.data.data);
      setCorporate(response.data.resume);
      setCdaList(response.data.data.data);
    } catch (e) {
      alertDispatch({
        type: 'SHOW_ALERT',
        payload: { message: 'Erro ao consultar!', severity: 'error' }
      });
    } finally {
      setNewSearch(true);
      setLoading(false);
    }
  }
  useEffect(() => {
    asyncGetAlert('', '', '');
  }, []);

  return {
    data,
    id,
    loading,
    setLoading,
    setData,
    alertDispatch,
    newSearch,
    getAlert,
    setNewSearch,
    setCdaList,
    corporate,
    cdaList
  };
}
