import { memo } from 'react';

import { useSnackbar } from 'hooks/useSnackbar';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomSnackbar = () => {
  const [alertState, alertDispatch] = useSnackbar();
  const { isAlert, severity, message } = alertState;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    alertDispatch({ type: 'CLOSE_ALERT' });
  };

  return (
    <Snackbar
      open={isAlert}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default memo(CustomSnackbar);
