import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRouter';

import Login from 'pages/Login';
import Search from 'pages/Search';
import ValidateCertificate from 'pages/ValidateCertificate';
import RegisterAlert from 'pages/RegisterAlert';
import ChangePassword from 'pages/ChangePassword';
import SearchHistory from 'pages/SearchHistory';
import RecoverPassword from 'pages/RecoverPassword';

import * as S from 'styles';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <S.MainWrapper>
          <Route path="/recover_password" component={RecoverPassword} />
          <PrivateRoute path="/change_password" component={ChangePassword} />
          <PrivateRoute path="/search_history" component={SearchHistory} />
          <PrivateRoute path="/alertas_cadastrados" component={RegisterAlert} />
          <PrivateRoute path="/consulta" component={Search} />
          <PrivateRoute path="/validate" component={ValidateCertificate} />
        </S.MainWrapper>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
