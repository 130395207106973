import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import AppLogoAdmin from 'assets/logo-user.png';
import { Toolbar as Content, AppBar as Wrapper } from '@material-ui/core';

export const AppBar = styled(Wrapper)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white} !important;
  `}
`;

export const Toolbar = styled(Content)`
  display: flex;
  justify-content: space-between;

  svg {
    fill: gray;
  }
`;

export const AppLogo = styled.img.attrs((props) => ({
  src: props.Img || AppLogoAdmin,
  alt: 'Logo do sistema CONSULTAMP.',
  title: 'Logo CONSULTAMP'
}))`
  width: 8rem;
  object-fit: contain;

  ${media.lessThan('small')`
    width: 6rem;
  `}
`;

export const Menu = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 2rem;
`;

export const MenuItem = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    border-bottom: 3px solid ${theme.colors.white};
  `};
  text-decoration: none;
  display: block;
  cursor: pointer;
  padding: 1.2rem;
  font-weight: medium;

  &:hover {
    ${({ theme }) => css`
      border-bottom: 3px solid ${theme.colors.primary};
      background-color: #fafafa;
      transition: all 0.4s ease;
    `}
  }
`;

export const UserName = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    margin-right: ${theme.spacings.xxsmall};
  `}
`;
