import { useState, useCallback } from 'react';
import { generate, certificate } from 'services/service';
import { useSnackbar } from 'hooks/useSnackbar';
import { useAuth } from 'hooks/useAuth';

export function useCertificate() {
  const { id } = useAuth();
  const [pdf, setPdf] = useState('');
  const [loadingCertificate, setLoadingCertificate] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  const downloadCertificate = useCallback((data) => {
    var blob = new Blob([data], { type: 'application/pdf' });
    var url = window.URL.createObjectURL(blob);
    window.open(url);
  }, []);

  const openCertificate = useCallback(
    async (pdf) => {
      try {
        const response = await certificate(pdf);
        downloadCertificate(response.data);

        alertDispatch({
          type: 'SHOW_ALERT',
          payload: { message: 'Certidão gerada com sucesso!' }
        });
      } catch (error) {
        setLoadingCertificate(false);
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: { message: 'Erro ao gerar certidão!', severity: 'error' }
        });
      } finally {
        setLoadingCertificate(false);
      }
    },
    [downloadCertificate, alertDispatch]
  );

  const createCertificate = useCallback(
    async (cda, processNumber, isTributary) => {
      if (cda && processNumber) {
        setLoadingCertificate(true);
        try {
          const response = await generate(cda, processNumber, isTributary, id);

          setPdf(response.data.data);
          openCertificate(response.data.data);
        } catch (error) {
          alertDispatch({
            type: 'SHOW_ALERT',
            payload: { message: 'Erro ao gerar certidão!', severity: 'error' }
          });
          console.log(error);
        } finally {
          setLoadingCertificate(false);
        }
      }
    },
    [id, openCertificate, alertDispatch]
  );

  return {
    pdf,
    createCertificate,
    loadingCertificate,
    setLoadingCertificate
  };
}
