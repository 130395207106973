import Routes from 'routes';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CustomSnackbar from 'components/Snackbar';

import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/globalStyles';
import Theme from 'styles/theme';

import { useAuth } from 'hooks/useAuth';

function App() {
  const { signed, signOut, user } = useAuth();

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyles />
      <Header signed={signed} signOut={signOut} user={user || ''} />
      <Routes />
      <CustomSnackbar />
      <Footer signed={signed} />
    </ThemeProvider>
  );
}

export default App;
