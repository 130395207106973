import { useMemo, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import {
  formatCurrency,
  formatCpf,
  formatCnpj,
  formatRegistrationNumber,
  convertForString,
  formatDate,
  formatDateDiff
} from 'utils/formatMask';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@material-ui/core';

import * as S from './styles';

import { useNotificationCda } from 'hooks/use-notification/useNotification';

const CardView = ({ data }) => {
  const {
    hasNotification,
    addNotificationCda,
    removeNotificationCda,
    existNotificationCda,
    loading
  } = useNotificationCda(data);

  useEffect(() => {
    existNotificationCda(data);
  }, [existNotificationCda, data]);

  const masks = useMemo(() => {
    const { document, registration_number, debt_balance } = data;
    return {
      currency: formatCurrency(debt_balance),
      document:
        String(document).length == 11
          ? formatCpf(document)
          : formatCnpj(document),
      registrationNumber: formatRegistrationNumber(registration_number),
      documentType: String(document).length == 11 ? 'CPF:' : 'CNPJ:'
    };
  }, [data]);

  const convertBoolean = useMemo(() => {
    const { judicially_charged, registry_charged } = data;
    return {
      judiciallyCharged: convertForString(judicially_charged),
      registryCharged: convertForString(registry_charged)
    };
  }, [data]);

  const summaryValues = useMemo(() => {
    if (data.debts) {
      return {
        mainValue: data.debts.reduce(
          (mainValue, debt) => mainValue + Number(debt.main_value),
          0
        ),
        fineValue: data.debts.reduce(
          (fineValue, debt) => fineValue + Number(debt.fine_value),
          0
        ),
        addition: data.debts.reduce(
          (addition, debt) => addition + Number(debt.addition),
          0
        )
      };
    } else {
      return null;
    }
  }, [data]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Name>{data.name}</S.Name>
        <S.Card>
          {hasNotification ? (
            <>
              {loading ? (
                <S.loadingAddAndRemove>
                  <CircularProgress size={24} />
                </S.loadingAddAndRemove>
              ) : (
                <S.NotificationRemoveButton
                  onClick={() => removeNotificationCda(data)}
                />
              )}
            </>
          ) : (
            <>
              {loading ? (
                <S.loadingAddAndRemove>
                  <CircularProgress size={24} />
                </S.loadingAddAndRemove>
              ) : (
                <S.NotificationAddButton
                  onClick={() => addNotificationCda(data)}
                />
              )}
            </>
          )}

          <Grid container>
            <Grid container justifyContent="space-between">
              <S.Grid item xs={11} sm={5} md={2} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  {data.is_tributary
                    ? String(data.document).length == 14
                      ? 'CPF:'
                      : 'CNPJ:'
                    : String(data.document).length == 11
                    ? 'CPF:'
                    : 'CNPJ:'}
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {masks.document}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={2} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Nº Inscrição:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {masks.registrationNumber}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={3} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Auto de Infração:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {String(data.infraction_notice) == '0' ||
                  !data.infraction_notice
                    ? '-'
                    : data.infraction_notice}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={4} lg={3}>
                <S.TypographyLabel variant="subtitle1">
                  Origem do Débito:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {data.debt_origin}
                </S.TypographyValue>
              </S.Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <S.Grid item xs={11} sm={5} md={2} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Tipo de Receita:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {data.revenue_type}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={2} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Fase Atual:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {data.current_step}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={4} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Saldo Devedor Atual:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {masks.currency}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={3} lg={3}>
                <S.TypographyLabel variant="subtitle1">
                  Protestado:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {convertBoolean.registryCharged}
                </S.TypographyValue>
              </S.Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <S.Grid item xs={11} sm={5} md={4} lg={3}>
                <S.TypographyLabel variant="subtitle1">
                  Ajuizado:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {convertBoolean.judiciallyCharged}
                </S.TypographyValue>
              </S.Grid>
              {data.show_installment_info && (
                <>
                  <S.Grid item xs={11} sm={5} md={2} lg={3}>
                    <S.TypographyLabel variant="subtitle1">
                      Sequencial de parcelamento:
                    </S.TypographyLabel>
                    <S.TypographyValue variant="body2">
                      {data.installment_info?.sequence}
                    </S.TypographyValue>
                  </S.Grid>
                  <S.Grid item xs={11} sm={5} md={2} lg={3}>
                    <S.TypographyLabel variant="subtitle1">
                      Quantidade de parcelas:
                    </S.TypographyLabel>
                    <S.TypographyValue variant="body2">
                      {data.installment_info?.quantity_of} (
                      {data.installment_info?.quantity_of_paid} Paga(s))
                    </S.TypographyValue>
                  </S.Grid>
                  <S.Grid item xs={11} sm={5} md={3} lg={3}>
                    <S.TypographyLabel variant="subtitle1">
                      Abertura do parcelamento:
                    </S.TypographyLabel>
                    <S.TypographyValue variant="body2">
                      {data.installment_info?.start_date}
                    </S.TypographyValue>
                  </S.Grid>
                </>
              )}
            </Grid>

            <Grid container justifyContent="space-between">
              <S.Grid item xs={11} sm={10} md={11} lg={9}>
                <S.TypographyLabel variant="subtitle1">
                  Situação da Dívida:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {data.status}
                </S.TypographyValue>
              </S.Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item xs={11} sm={10} md={11} lg={9}>
                <S.TypographyLabel variant="subtitle1">
                  Composição do Saldo Devedor
                  {data.is_tributary && data.debts.length > 0 && '*'}:
                </S.TypographyLabel>
              </Grid>

              {data.debts.length > 0 ? (
                <TableContainer>
                  <Table aria-label="Tabela de débitos">
                    <TableHead>
                      <TableRow style={{ fontWeight: 'Bold' }}>
                        <S.HeaderTableCell align="right">
                          Referência Inicial
                        </S.HeaderTableCell>
                        <S.HeaderTableCell align="right">
                          Referência Final
                        </S.HeaderTableCell>
                        <S.HeaderTableCell align="right">
                          Vencimento
                        </S.HeaderTableCell>
                        <S.HeaderTableCell align="right">
                          Valor Principal
                        </S.HeaderTableCell>
                        <S.HeaderTableCell align="right">
                          Valor Multa{data.is_tributary && '**'}
                        </S.HeaderTableCell>
                        <S.HeaderTableCell align="right">
                          Acréscimos
                        </S.HeaderTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.debts.map((row) => (
                        <TableRow
                          key={row.initial_reference}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}
                        >
                          <S.DebtsTableCell align="right">
                            {formatDate(row.initial_reference)}
                          </S.DebtsTableCell>
                          <S.DebtsTableCell align="right">
                            {formatDate(row.final_reference).length > 0
                              ? formatDate(row.final_reference)
                              : '-'}
                          </S.DebtsTableCell>
                          <S.DebtsTableCell align="right">
                            {formatDateDiff(row.due)}
                          </S.DebtsTableCell>
                          <S.DebtsTableCell align="right">
                            {formatCurrency(row.main_value)}
                          </S.DebtsTableCell>
                          <S.DebtsTableCell align="right">
                            {formatCurrency(row.fine_value)}
                          </S.DebtsTableCell>
                          <S.DebtsTableCell align="right">
                            {data.is_tributary && data.debts.length > 1
                              ? '-'
                              : formatCurrency(row.addition)}
                          </S.DebtsTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <S.FooterTableCell align="right">
                        {formatCurrency(summaryValues.mainValue)}
                      </S.FooterTableCell>
                      <S.FooterTableCell align="right">
                        {formatCurrency(summaryValues.fineValue)}
                      </S.FooterTableCell>
                      <S.FooterTableCell align="right">
                        {formatCurrency(summaryValues.addition)}
                      </S.FooterTableCell>
                    </TableFooter>
                  </Table>

                  {data.is_tributary && (
                    <S.TributaryAlertGrid>
                      <div>
                        *A Composição do Saldo Devedor é referente ao dia
                        {' ' + formatDateDiff(data.reference_date)}.
                      </div>
                      <div>
                        **Multas autônomas estão inclusas na coluna Valor Multa.
                      </div>
                    </S.TributaryAlertGrid>
                  )}
                </TableContainer>
              ) : (
                <div
                  style={{
                    color: '#939393',
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  Não foram encontrados débitos para a CDA.
                </div>
              )}
            </Grid>
          </Grid>
        </S.Card>
      </S.Content>
    </S.Wrapper>
  );
};

CardView.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
};

export default memo(CardView);
