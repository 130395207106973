import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import {
  Card as CardWrapper,
  Grid as GridWrapper,
  Typography as Label,
  Typography as Value,
  Button as btn
} from '@material-ui/core';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
  `}
`;

export const Card = styled(CardWrapper)`
  display: flex;

  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall};
  `}
`;
export const Grid = styled(GridWrapper)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall} !important;

    ${media.lessThan('600px')`
      margin-bottom: ${theme.spacings.xxsmall} !important;
      width: 50%;
    `}

    ${media.between('600px', '960px')`
      width: 40%;
    `}

    ${media.between('960px', '1510px')`
      width: 30%;
    `}

    ${media.greaterThan('1510px')`
      width: 11.5%;
    `}
  `}
`;

export const TypographyLabel = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.black} !important;
    font-family: ${theme.font.family} !important;
    font-weight: ${theme.font.bolder} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;
export const TypographyValue = styled(Value)`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray} !important;
    font-family: ${theme.font.family} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;
export const Button = styled(btn)`
  width: auto !important;
  margin: 0.5rem !important;
  max-height: 2.5rem;
  ${({ theme }) => css`
    background: ${theme.colors.mpColor} !important;
    color: ${theme.colors.white} !important;
  `}
  ${media.lessThan('1024px')`
  height: 2.5rem;
  font-size: 1rem !important;
    ${({ theme }) => css`
      margin-top: ${theme.spacings.xxsmall} !important;
    `}
  `}
  ${media.between('1025px', '1366px')`
    font-size: .7rem !important;
  `}
`;

export const ButtonRemove = styled(btn)`
  width: 100%;
  margin: 0.5rem !important;
  max-height: 2.5rem;
  ${({ theme }) => css`
    background: ${theme.colors.removeColor} !important;
    color: ${theme.colors.white} !important;
  `}
  ${media.lessThan('1024px')`
  height: 2.5rem;
  font-size: 1rem !important;
    width: 100%;
    ${({ theme }) => css`
      margin-top: ${theme.spacings.xxsmall} !important;
    `}
  `}
  ${media.between('1025px', '1366px')`
    font-size: .7rem !important;
  `}
`;
export const CardActions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DialogContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DialogTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
  margin-bottom: 1.8rem !important;
  width: 18rem;
  text-align: center;
`;
export const DialogSubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
  margin-bottom: 1.8rem !important;
  width: 20rem;
  text-align: center;
`;

export const DialogForm = styled.form`
  display: flex;
`;
