import PropTypes from 'prop-types';

import * as S from './styles';

const Footer = ({ signed }) => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {signed && (
        <S.Wrapper>
          <S.Container>
            <S.InstitucionalLogo />
            <S.ContactInfo>
              <div>
                <h2>NOSSO SUPORTE</h2>
                <p>(85) 3459-6325</p>
              </div>
              <S.SocialInfo>
                <h2>NOSSOS CANAIS</h2>
                <a
                  href="https://facebook.com/pgeceara/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <S.SocialFacebookIcon />
                </a>
                <a
                  href="https://instagram.com/pgeceara/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <S.SocialInstagramIcon />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCo1jioFflKExElezg3thf-g"
                  target="_blank"
                  rel="noreferrer"
                >
                  <S.SocialYoutubeIcon />
                </a>
              </S.SocialInfo>
            </S.ContactInfo>
            <S.Text>
              © {currentYear} – <span>GOVERNO DO ESTADO DO CEARÁ</span> TODOS OS
              DIREITOS RESERVADOS
            </S.Text>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  );
};

Footer.propTypes = {
  signed: PropTypes.bool
};

export default Footer;
