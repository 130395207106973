import { useSearch } from 'hooks/use-search';
import { useEffect, useState } from 'react';
import { useCertificate } from 'hooks/use-certificate';
import { Pagination } from '@material-ui/lab';

import { useFormik } from 'formik';
import { certificateValidateSchema } from 'utils/validations';

import CardInfo from 'components/CardInfo';
import CardSearch from 'components/CardSearch';
import CardView from 'components/CardView';
import Dialog from 'components/Dialog';

import FormSearch from 'components/FormSearch';
import SkeletonLoading from 'components/SkeletonLoading/Search';

import { MdBorderColor } from 'react-icons/md';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CircularProgress } from '@material-ui/core';

import usePagination from './Pagination';

import * as S from './styles';
import { Grid } from '@material-ui/core';
const Search = () => {
  let [page, setPage] = useState(1);

  const {
    data,
    getSearch,
    loading,
    cdaList,
    setCdaList,
    newSearch,
    setNewSearch,
    corporate
  } = useSearch();
  const { createCertificate, loadingCertificate } = useCertificate();

  let [clicked, setClicked] = useState(false);
  let [cdaClicked, setCdaClicked] = useState({});
  const viewClick = (data) => {
    setClicked(true);
    setCdaClicked(data);
  };
  // pagination of cda list
  const PER_PAGE = 10;
  const CDA_LIST_SIZE = typeof cdaList !== 'undefined' ? cdaList.length : 1;
  const count = Math.ceil(CDA_LIST_SIZE / PER_PAGE);
  const _DATA = usePagination(cdaList, PER_PAGE);
  const NAME = typeof cdaList[0] !== 'undefined' ? cdaList[0].name : '';

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    if (newSearch) {
      setPage(1);
      _DATA.jump(1);

      setNewSearch(false);
    }
  }, [newSearch, page, _DATA, setNewSearch]);

  //pagination of corporate name
  const corporate_name =
    typeof corporate !== 'undefined' ? corporate.length : 1;
  const counting = Math.ceil(corporate_name / PER_PAGE);
  const CORPORATE = usePagination(corporate, PER_PAGE);

  const handleChanges = (e, p) => {
    setPage(p);
    CORPORATE.jump(p);
  };

  useEffect(() => {
    if (newSearch) {
      setPage(1);
      CORPORATE.jump(1);

      setNewSearch(false);
    }
  }, [newSearch, page, CORPORATE, setNewSearch]);

  const formik = useFormik({
    initialValues: { processNumber: '' },
    validationSchema: certificateValidateSchema,
    onSubmit: async ({ processNumber }) => {
      let certificateDate = {
        registration_number: 0,
        isTributary: false
      };

      if (!!NAME && !loading && cdaList.length == 1) {
        certificateDate = cdaList[0];
      } else {
        certificateDate = cdaClicked;
      }

      await createCertificate(
        certificateDate.registration_number,
        processNumber,
        certificateDate.is_tributary
      );
      formik.resetForm();
    }
  });
  return (
    <S.Wrapper>
      <S.Content>
        <CardInfo
          gender="she"
          text="Use um dos campos do formulário abaixo para requisitar informações sobre a situação de uma inscrição."
        />
        <FormSearch
          setClicked={setClicked}
          setCdaList={setCdaList}
          getSearch={getSearch}
          loading={loading}
        />
        {loading && <SkeletonLoading />}
        {data.length == 0 && !loading && (
          <S.ResultNoCDA>
            Não foi encontrado nenhuma Dívida Ativa para essa consulta.
          </S.ResultNoCDA>
        )}
        {!!NAME && !loading && cdaList.length > 1 && !clicked && (
          <>
            {data[0].document_type == 'CPF' ? (
              <S.Name> {NAME} </S.Name>
            ) : (
              <>
                <S.CardActions>
                  <Dialog
                    btnText={NAME}
                    type="social"
                    tooltipOn={true}
                    tooltipContent={
                      'Clique no nome da empresa para verificar histórico de razão social.'
                    }
                  >
                    <S.DialogContainerReason>
                      <S.Names>
                        CNPJs ASSOCIADOS E RAZÕES SOCIAIS ENCONTRADAS
                      </S.Names>
                      {corporate.map((option) => {
                        return (
                          <S.Card
                            key={option.id}
                            value={option.id}
                            style={{
                              boxShadow: '0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.1)'
                            }}
                          >
                            <Grid container>
                              <Grid container justifyContent="start">
                                <S.Grid item xs={11} sm={5} md={2} lg={2}>
                                  <S.TypographyLabel variant="subtitle1">
                                    CNPJ:
                                  </S.TypographyLabel>
                                  <S.TypographyValue variant="body2">
                                    {option.document}
                                  </S.TypographyValue>
                                </S.Grid>
                                <S.Grid item xs={11} sm={5} md={2} lg={2}>
                                  <S.TypographyLabel variant="subtitle1">
                                    Razão Social:
                                  </S.TypographyLabel>
                                  <S.TypographyValue variant="body2">
                                    {option.social_name}
                                  </S.TypographyValue>
                                </S.Grid>
                              </Grid>
                            </Grid>
                          </S.Card>
                        );
                      })}
                      <S.ContentPaginate>
                        <S.TotalRegisters
                          style={{ fontWeight: '300', color: '#575757' }}
                        >
                          {corporate.length}
                          {corporate.length == 1
                            ? '  CNPJ/Razões Social'
                            : '  CNPJs/Razões Sociais'}
                        </S.TotalRegisters>
                        <S.Paginate>
                          <Pagination
                            counting={counting}
                            size="large"
                            page={page}
                            shape="rounded"
                            onChange={handleChanges}
                          />
                        </S.Paginate>
                      </S.ContentPaginate>
                    </S.DialogContainerReason>
                  </Dialog>
                </S.CardActions>
              </>
            )}
            {_DATA.currentData().map((cda) => {
              return (
                <CardSearch
                  data={cda}
                  key={cda.registration_number}
                  viewClick={viewClick}
                />
              );
            })}
            <S.ContentPaginate>
              <S.TotalRegisters>{data.length} Inscrições</S.TotalRegisters>
              <S.Paginate>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  shape="rounded"
                  onChange={handleChange}
                />
              </S.Paginate>
            </S.ContentPaginate>
          </>
        )}

        {!!NAME && !loading && cdaList.length == 1 && (
          <>
            <CardView data={cdaList[0]} />
            <S.CardActions>
              <Dialog btnText="GERAR CERTIDÃO">
                <S.DialogContainer>
                  <S.DialogTitle>
                    Informar número de processo relacionado a essa requisição.
                  </S.DialogTitle>
                  <S.DialogForm onSubmit={formik.handleSubmit}>
                    <S.DialogInput
                      value={formik.values.processNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.processNumber &&
                        !!formik.errors.processNumber
                      }
                      helperText={
                        formik.touched.processNumber &&
                        formik.errors.processNumber
                      }
                      name="processNumber"
                      id="processNumber"
                      placeholder="Número do Processo"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {<MdBorderColor />}
                          </InputAdornment>
                        )
                      }}
                    />
                    <S.DialogSubmit type="submit">
                      {loadingCertificate ? (
                        <CircularProgress size={24} />
                      ) : (
                        'GERAR CERTIDÃO'
                      )}
                    </S.DialogSubmit>
                  </S.DialogForm>
                </S.DialogContainer>
              </Dialog>
              {data.data}
            </S.CardActions>
          </>
        )}

        {clicked && (
          <>
            <CardView data={cdaClicked} />
            <S.CardActions>
              <Dialog btnText="GERAR CERTIDÃO">
                <S.DialogContainer>
                  <S.DialogTitle>
                    Informar número de processo relacionado a essa requisição.
                  </S.DialogTitle>
                  <S.DialogForm onSubmit={formik.handleSubmit}>
                    <S.DialogInput
                      value={formik.values.processNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.processNumber &&
                        !!formik.errors.processNumber
                      }
                      helperText={
                        formik.touched.processNumber &&
                        formik.errors.processNumber
                      }
                      name="processNumber"
                      id="processNumber"
                      placeholder="Número do Processo"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {<MdBorderColor />}
                          </InputAdornment>
                        )
                      }}
                    />
                    <S.DialogSubmit type="submit">
                      {loadingCertificate ? (
                        <CircularProgress size={24} />
                      ) : (
                        'GERAR CERTIDÃO'
                      )}
                    </S.DialogSubmit>
                  </S.DialogForm>
                </S.DialogContainer>
              </Dialog>
              {data.data}
            </S.CardActions>
          </>
        )}
      </S.Content>
    </S.Wrapper>
  );
};
export default Search;
