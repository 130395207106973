import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import PgeLogo from '../../assets/logogov-footer.png';
import FacebookIcon from '../../assets/facebook-icon.png';
import InstagramIcon from '../../assets/instagram-icon.png';
import YoutubeIcon from '../../assets/youtube-icon.png';

export const Wrapper = styled.footer`
  width: 100%;
  margin-top: auto;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4rem;
  background-color: #ebebeb !important;
  border-top: 30px solid transparent;
  -moz-border-image: -moz-linear-gradient(
    left,
    rgba(16, 101, 99, 1) 0%,
    rgba(16, 101, 99, 1) 15%,
    rgba(43, 155, 153, 1) 15%,
    rgba(43, 155, 153, 1) 59%,
    rgba(78, 187, 185, 1) 59%,
    rgba(78, 187, 185, 1) 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    left,
    rgba(16, 101, 99, 1) 0%,
    rgba(16, 101, 99, 1) 15%,
    rgba(43, 155, 153, 1) 15%,
    rgba(43, 155, 153, 1) 59%,
    rgba(78, 187, 185, 1) 59%,
    rgba(78, 187, 185, 1) 100%
  );
  border-image: linear-gradient(
    to right,
    rgba(16, 101, 99, 1) 0%,
    rgba(16, 101, 99, 1) 15%,
    rgba(43, 155, 153, 1) 15%,
    rgba(43, 155, 153, 1) 59%,
    rgba(78, 187, 185, 1) 59%,
    rgba(78, 187, 185, 1) 100%
  );
  border-image-slice: 1;
  ${({ theme }) => css`
    background: ${theme.colors.white};
  `}
  ${media.lessThan('935px')`
    flex-direction: column !important;
    align-content: space-between;
    padding: 7rem;
  `}
`;

export const InstitucionalLogo = styled.img.attrs((props) => ({
  src: props.Img || PgeLogo,
  alt: 'Logo da Procuradoria Geral do Estado do Ceará.',
  title: 'Logo PGE'
}))`
  width: 13rem;
  object-fit: contain;

  ${media.lessThan('935px')`
    padding-bottom: 2rem;
  `}
`;

export const SocialFacebookIcon = styled.img.attrs((props) => ({
  src: props.Img || FacebookIcon,
  alt: 'Logo Facebook da Procuradoria Geral do Estado do Ceará.',
  title: 'Logo PGE'
}))`
  margin-right: 5px;
  width: 1.8rem;
  object-fit: contain;
`;

export const SocialInstagramIcon = styled.img.attrs((props) => ({
  src: props.Img || InstagramIcon,
  alt: 'Logo Instagram da Procuradoria Geral do Estado do Ceará.',
  title: 'Logo PGE'
}))`
  margin-right: 5px;
  width: 1.8rem;
  object-fit: contain;
`;

export const SocialYoutubeIcon = styled.img.attrs((props) => ({
  src: props.Img || YoutubeIcon,
  alt: 'Logo YoutTube da Procuradoria Geral do Estado do Ceará.',
  title: 'Logo PGE'
}))`
  margin-right: 5px;
  width: 1.8rem;
  object-fit: contain;
`;

export const Text = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};

    > span {
      font-weight: ${theme.font.bold};
    }
  `}
  ${media.lessThan('935px')`
    text-align: center;
  `}
`;

export const ContactInfo = styled.span`
  display: flex;
  margin: 0 2rem;

  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
  `}
  ${media.lessThan('935px')`
    flex-direction: column !important;
    text-align: center;
    margin: 2rem 0;
  `}
`;

export const SocialInfo = styled.span`
  margin: 0 3rem;

  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};

    > span {
      font-weight: ${theme.font.bold};
    }
  `}
  ${media.lessThan('935px')`
    text-align: center;
    margin: 2rem 0;
  `}
`;
