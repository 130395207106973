import styled from 'styled-components';
import media from 'styled-media-query';

import { Typography, Paper } from '@material-ui/core';
import { styled as muiStyled } from '@material-ui/core/styles';
import { TimelineDot as Dot } from '@material-ui/lab';

export const TimelineDot = muiStyled(Dot)({
  borderColor: '#4ebbb9'
});

export const WrapperDate = styled.div`
  display: flex;
`;

export const Hour = styled(Typography)`
  color: gray;
`;

export const Day = styled(Typography)`
  margin-right: 0.3rem !important;
  color: gray;
`;

export const Month = styled(Typography)`
  margin-right: 0.3rem !important;
  color: gray;
`;

export const Year = styled(Typography)`
  color: gray;
  ${media.lessThan('331px')`
    font-size: 0.8rem !important;
  `}
`;

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.3rem;
  border: 1px solid #bdbdbd;
`;

export const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Name = styled.h3`
  color: #4ebbb9;
`;

export const Document = styled(Typography)`
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #3a3a3a;
`;
