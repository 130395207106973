import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { TextField as input, Button as btn } from '@material-ui/core';

export const TotalRegisters = styled.span`
  ${({ theme }) => css`
    float: left;
    color: ${theme.colors.secondary};
    font-weight: ${theme.font.light};
    font-size: ${theme.font.sizes.xxsmall};
    flex: 1;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall};
    `}
  `}
`;
export const Paginate = styled.div`
  display: flex;
  float: right;
`;
export const ContentPaginate = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    display: flex;
    justify-content: space-between;
  `}
`;

export const ResultNoCDA = styled.h3`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardActions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const DialogContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DialogTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
  margin-bottom: 1.8rem !important;
  width: 18rem;
  text-align: center;
`;

export const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const DialogInput = styled(input)`
  .MuiOutlinedInput-input {
    width: 18.7rem;
  }
  svg {
    fill: gray;
  }
`;
export const DialogSubmit = styled(btn)`
  ${({ theme }) => css`
    background: linear-gradient(
      90deg,
      ${theme.colors.gradientBegin} 0%,
      ${theme.colors.gradientEnd} 100%
    );
    color: ${theme.colors.white} !important;
  `}
  margin-top: 1rem !important;
  width: 22rem;
  border: none;
  border-radius: 0.3rem;
`;
