import * as S from './styles';

const SkeletonSearchHistory = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.Loading color="info" />
        <S.Text>Carregando seu histórico...</S.Text>
      </S.Content>
    </S.Wrapper>
  );
};

export default SkeletonSearchHistory;
