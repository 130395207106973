import { useState, useEffect, useRef } from 'react';
import { history } from 'services/service';
import { useSnackbar } from 'hooks/useSnackbar';

export function useSearchHistory() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState();

  const loader = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await history(page);
        const date = response.data.data.map((item) => {
          let dataHistory;
          const { name, registration_number } = item.response;
          dataHistory = {
            ...item.updated_at_detail,
            name,
            registration_number,
            id: item.id
          };

          return dataHistory;
        });
        setPageSize(response.data.page_size);
        setData((current) => [...current, ...date]);
      } catch (error) {
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: 'Nenhum cadastro foi encontrado com o CPF informado!',
            severity: 'error'
          }
        });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [page, alertDispatch]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting) && pageSize > 0) {
        setPage((currentValue) => currentValue + 1);
      }
    });

    intersectionObserver.observe(loader.current);

    return () => intersectionObserver.disconnect();
  }, [pageSize]);

  return {
    data,
    page,
    setPage,
    loader,
    loading,
    pageSize
  };
}
