import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';

import { forgotPasswordSchema } from 'utils/validations';

import * as S from './styles';

import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';

const recaptchaRef = React.createRef();
const FormForgotPassword = ({ sendForgotPassword, loading }) => {
  const formik = useFormik({
    initialValues: { cpf: '', system: 4 },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (data) => {
      await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      await sendForgotPassword(data);
      formik.resetForm();
    }
  });

  return (
    <S.DialogForm onSubmit={formik.handleSubmit}>
      <NumberFormat
        customInput={S.DialogInput}
        format={'###.###.###-##'}
        value={formik.values.cpf}
        onChange={formik.handleChange}
        error={formik.touched.cpf && !!formik.errors.cpf}
        helperText={formik.touched.cpf && formik.errors.cpf}
        name="cpf"
        id="cpf"
        placeholder="Digite o CPF"
        variant="outlined"
        size="small"
      />
      <S.DialogSubmit disabled={loading} type="submit">
        {loading ? <CircularProgress size={24} /> : 'Buscar'}
      </S.DialogSubmit>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
        ref={recaptchaRef}
        size="invisible"
      />
    </S.DialogForm>
  );
};

FormForgotPassword.propTypes = {
  sendForgotPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default FormForgotPassword;
