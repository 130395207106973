import styled, { css } from 'styled-components';
import { Button as btn, TextField as input } from '@material-ui/core';
import media from 'styled-media-query';

import PgeLogo from '../../assets/logo-pge.png';
import AppLogoAdmin from '../../assets/logo-user.png';
import LoginImage from '../../assets/i-login-user.svg';
import PortalContribuinteImg from '../../assets/portal-contribuinte-img.png';

export const TextField = styled(input)`
  .MuiOutlinedInput-input {
    width: 16rem;
  }

  svg {
    fill: gray;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerBlock = styled.div`
  height: 100vh;
  width: 30vw;

  ${({ theme }) => css`
    ${media.lessThan('935px')`
      display: none;
    `}
    background: linear-gradient(
      to right bottom,
      ${theme.colors.gradientBegin},
      ${theme.colors.gradientEnd}
    );
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;
export const Image = styled.img.attrs((props) => ({
  src: props.Img || LoginImage,
  alt: 'Pessoa na mesa de seu escritório usando um computador.',
  title: 'Ilustração da página de login.'
}))`
  object-fit: contain;
  width: 32vw;
  ${media.lessThan('935px')`
    display: none;
  `}
  ${media.greaterThan('2560px')`
    width: 25vw;
  `}
`;

export const Content = styled.div`
  display: flex;
  padding-top: 3rem;
  padding-bottom: 0.1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  ${({ theme }) => css`
    gap: ${theme.spacings.xxsmall};
  `}
`;

export const Button = styled(btn)`
  ${({ theme }) => css`
    background: ${(props) =>
      props.disabled
        ? theme.colors.lightGray
        : theme.colors.mpColor} !important;
    color: ${theme.colors.white} !important;
  `}
  height: 3rem;
  border: none;
  border-radius: 0.3rem;
`;

export const InstitucionalLogo = styled.img.attrs((props) => ({
  src: props.Img || PgeLogo,
  alt: 'Logo da Procuradoria Geral do Estado do Ceará.',
  title: 'Logo PGE'
}))`
  margin-bottom: 0.5rem;
  width: 6rem;
  object-fit: contain;

  ${media.lessThan('375px')`
    width: 6rem;
  `}
`;

export const AppLogo = styled.img.attrs((props) => ({
  src: props.Img || AppLogoAdmin,
  alt: 'Logo do sistema CONSULTAMP.',
  title: 'Logo CONSULTAMP'
}))`
  width: 13rem;
  object-fit: contain;
  margin: 1rem;

  ${media.lessThan('375px')`
    width: 10rem;
  `}
`;

export const PortalContribuinteFooterBanner = styled.img.attrs((props) => ({
  src: props.Img || PortalContribuinteImg,
  alt: 'Logo do Portal do Contribuinte.',
  title: 'Logo PGE'
}))`
  margin-top: 5rem;
  width: 25rem;
  object-fit: contain;

  ${media.lessThan('375px')`
    width: 6rem;
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    width: 20rem;
    font-size: 1rem;
    margin-bottom: ${theme.spacings.xxsmall};
    text-align: center;
  `}

  ${media.lessThan('375px')`
    width: 15rem;
  `}
`;

export const DialogContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DialogTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
  width: 28rem;
  text-align: center;
`;

export const DialogSubTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray};
  `}
  margin-bottom: 1.8rem !important;
  width: 28rem;
  font-weight: 400;
  text-align: center;
`;

export const PortalContribuinteDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
`;

export const UrlPortalContribuinte = styled.a.attrs(() => ({
  href: 'https://portaldocontribuinte.pge.ce.gov.br',
  target: '_blank',
  rel: 'noopener noreferrer'
}))`
  font-weight: bold;
  font-size: 0.9rem;
  text-decoration: none;
  ${({ theme }) => css`
    color: ${theme.colors.primary} !important;
  `}
  }
  ${media.lessThan('375px')`
    font-size: 0.6rem;
  `}
`;

export const LinkPortalContribuinte = styled.a.attrs(() => ({
  href: 'https://portaldocontribuinte.pge.ce.gov.br',
  target: '_blank',
  rel: 'noopener noreferrer'
}))`
  font-weight: bold;
  margin-top: 2rem;
  text-decoration: none;
  ${({ theme }) => css`
    color: ${theme.colors.black} !important;
  `}
`;
