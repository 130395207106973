import PropTypes from 'prop-types';

import { useAuth } from 'hooks/useAuth';

import * as S from './styles';

const CardInfo = ({ gender, text }) => {
  const { user } = useAuth();

  return (
    <S.Card>
      <S.Text>
        <S.Title>Bem vindo {user}!</S.Title>
        <S.Description>{text}</S.Description>
      </S.Text>
      <S.Image genderType={gender} />
    </S.Card>
  );
};
export default CardInfo;

CardInfo.propTypes = {
  user: PropTypes.string,
  gender: PropTypes.string,
  text: PropTypes.string.isRequired
};
