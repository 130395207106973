import { useState, useCallback } from 'react';
import { useSnackbar } from 'hooks/useSnackbar';

import { changePassword } from 'services/service';

export function useChangePassword() {
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  const newChangePassword = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const response = await changePassword(values);
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: { message: response.data.message }
        });
      } catch (error) {
        setLoading(false);
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: 'Não foi possível alterar a senha!',
            severity: 'error'
          }
        });
      } finally {
        setLoading(false);
      }
    },
    [alertDispatch]
  );

  return { newChangePassword, loading };
}
