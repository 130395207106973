import styled, { css } from 'styled-components';

import { TextField as input, Button as btn } from '@material-ui/core';
import {
  Card as CardWrapper,
  Grid as GridWrapper,
  Typography as Label,
  Typography as Value
} from '@material-ui/core';

import media from 'styled-media-query';

export const TotalRegisters = styled.span`
  ${({ theme }) => css`
    float: left;
    color: ${theme.colors.secondary};
    font-weight: ${theme.font.light};
    font-size: ${theme.font.sizes.xxsmall};
    flex: 1;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall};
    `}
  `}
`;

export const Paginate = styled.div`
  display: flex;
  float: right;
`;
export const ContentPaginate = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    display: flex;
    justify-content: space-between;
  `}
`;

export const Wrapper = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.font.light};
    margin-top: ${theme.spacings.small};

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xsmall};
    `}
  `}
`;

export const CardActions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DialogContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DialogTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
  margin-bottom: 1.8rem !important;
  width: 18rem;
  text-align: center;
`;

export const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const DialogInput = styled(input)`
  .MuiOutlinedInput-input {
    width: 18.7rem;
  }
  svg {
    fill: gray;
  }
`;

export const DialogSubmit = styled(btn)`
  ${({ theme }) => css`
    background: linear-gradient(
      90deg,
      ${theme.colors.gradientBegin} 0%,
      ${theme.colors.gradientEnd} 100%
    );
    color: ${theme.colors.white} !important;
  `}
  margin-top: 1rem !important;
  width: 22rem;
  border: none;
  border-radius: 0.3rem;
`;

export const ResultNoCDA = styled.h3`
  margin-top: 2rem;
`;
// GRID PARA CONTEUDOS
export const Names = styled.h2`
  padding-top: 3.5rem;
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.font.light};
    margin-bottom: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.medium};

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xsmall};
    `}
  `}
`;
export const DialogContainerReason = styled.div`
  display: flex;
  padding-top: 4rem;
  width: 80%;
  margin-left: 10% !important;
  justify-content: center;
  flex-direction: column;
`;
export const Card = styled(CardWrapper)`
  display: flex;
  margin-top: 1rem !important;
  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall};
  `}

  ${media.lessThan('600px')`
    padding: 0.5rem;
  `}
`;
export const Grid = styled(GridWrapper)`
  margin-left: 2rem !important;
  ${({ theme }) => css`
    ##margin-bottom: ${theme.spacings.xsmall} !important;

    ${media.lessThan('600px')`
      ##margin-bottom: ${theme.spacings.xxsmall} !important;
    `}
  `}
`;

export const TypographyLabel = styled(Label)`
  padding-top: 1rem !important;
  ${({ theme }) => css`
    color: ${theme.colors.black} !important;
    font-family: ${theme.font.family} !important;
    font-weight: ${theme.font.bolder} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;
export const TypographyValue = styled(Value)`
  padding-bottom: 1rem !important;
  ${({ theme }) => css`
    color: ${theme.colors.lightGray} !important;
    font-family: ${theme.font.family} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;
