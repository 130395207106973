import { useCallback, useState } from 'react';
import {
  existsNotification,
  removeNotification,
  addNotification
} from 'services/service';
import { useSnackbar } from 'hooks/useSnackbar';

export function useNotificationCda() {
  const [hasNotification, setHasNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  const addNotificationCda = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const response = await addNotification({
          userId: localStorage.getItem('id'),
          cda: data.registration_number,
          document: data.document,
          ai: data.infraction_notice,
          current_step: data.current_step,
          registry_charged: data.registry_charged,
          judicially_charged: data.judicially_charged,
          sequence: data.installment_info?.sequence,
          quantity_of: data.installment_info?.quantity_of,
          quantity_of_paid: data.installment_info?.quantity_of_paid,
          start_date: data.installment_info?.start_date,
          name: data.name
        });
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: response.data.message
          }
        });
        setHasNotification(true);
      } catch (e) {
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: e.response.data.message,
            severity: 'error'
          }
        });
      } finally {
        setLoading(false);
      }
    },
    [alertDispatch]
  );

  const removeNotificationCda = useCallback(
    async (data) => {
      try {
        let cda_or_registration = data.cda;

        if (data.cda) {
          cda_or_registration = data.cda;
        } else {
          cda_or_registration = data.registration_number;
        }
        setLoading(true);
        const response = await removeNotification({
          userId: localStorage.getItem('id'),
          cda: cda_or_registration
        });
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: response.data.message
          }
        });
        setHasNotification(false);
      } catch (e) {
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: e.response.data.message,
            severity: 'error'
          }
        });
      } finally {
        setLoading(false);
      }
    },
    [alertDispatch]
  );

  const existNotificationCda = useCallback(
    async (data) => {
      try {
        const response = await existsNotification({
          userId: localStorage.getItem('id'),
          cda: data.registration_number
        });
        setHasNotification(response.data.existe);
      } catch (e) {
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: e.response.data.message,
            severity: 'error'
          }
        });
      }
    },
    [alertDispatch]
  );

  return {
    hasNotification,
    addNotificationCda,
    removeNotificationCda,
    existNotificationCda,
    loading
  };
}
