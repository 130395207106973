import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { useForgotPassword } from 'hooks/useForgotPassword';

import Dialog from 'components/Dialog';
import FormForgotPassword from 'components/FormForgotPassword';
import Form from './Form';

import * as S from './styles';

const Login = () => {
  const { signIn, loadingBtnLogin, signed } = useAuth();
  const history = useHistory();
  const { sendForgotPassword, loading } = useForgotPassword();

  //Descobrir outra maneira de evitar que o usuário acesse a página de login
  //estando logado
  useEffect(() => {
    if (signed) {
      history.push('/consulta');
    }
  });

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Content>
          <S.InstitucionalLogo />
          <S.AppLogo />
          <S.Title>Bem vindo!</S.Title>
          <S.Description>
            Serviço disponibilizado para a requisição de informações pelo
            Ministério Público relacionadas à dívida ativa estadual.
          </S.Description>
          <Form signIn={signIn} loadingBtnLogin={loadingBtnLogin} />
          <Dialog btnText="Esqueceu a senha?" type="login">
            <S.DialogContainer>
              <S.DialogTitle>Recuperar Senha</S.DialogTitle>
              <S.DialogSubTitle>
                Insira seu CPF para buscar a sua conta.
              </S.DialogSubTitle>
              <FormForgotPassword
                sendForgotPassword={sendForgotPassword}
                loading={loading}
              />
            </S.DialogContainer>
          </Dialog>
          <S.PortalContribuinteFooterBanner />
          <S.PortalContribuinteDescription>
            <S.UrlPortalContribuinte>
              Acesse: portaldocontribuinte.pge.ce.gov.br
            </S.UrlPortalContribuinte>
            <S.LinkPortalContribuinte>
              Clique e saiba mais
            </S.LinkPortalContribuinte>
          </S.PortalContribuinteDescription>
        </S.Content>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default Login;
