import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;
export const Loading = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #4ebbb9 !important;
  }
`;
export const Text = styled.span``;
