export default {
  border: {
    radius: '0.5rem'
  },
  font: {
    family:
      "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    bolder: 500,
    bold: 600,
    sizes: {
      xxsmall: '0.8rem',
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem'
    }
  },
  colors: {
    primary: '#4EBBB9',
    gradientBegin: '#4EBBB9',
    gradientEnd: '#4EBBB9',
    mainBg: '#ebebeb',
    offWhite: '#FAFAFA',
    white: '#FFFFFF',
    black: '#2F2F2F',
    lightGray: '#939393',
    gray: '#555555',
    mpColor: '#4EBBB9',
    removeColor: '#E86553'
  },
  spacings: {
    xxxsmall: '0.0rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  }
};
