import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Toolbar, IconButton, Slide, Dialog } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { MdInfo } from 'react-icons/md';
import * as S from './styles';
import Tooltip from '@mui/material/Tooltip';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({
  type,
  btnText,
  children,
  tooltipOn = false,
  tooltipContent
}) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <S.Wrapper>
      {tooltipOn ? (
        <S.OpenDialog type={type} onClick={handleClickOpen}>
          {btnText}
          <Tooltip title={tooltipContent} placement="right">
            <div style={{ marginBottom: '3px' }}>
              <MdInfo size={17} />
            </div>
          </Tooltip>
        </S.OpenDialog>
      ) : (
        <S.OpenDialog type={type} onClick={handleClickOpen}>
          {btnText}
        </S.OpenDialog>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <S.AppBarModal>
          <Toolbar>
            <IconButton edge="start" onClick={handleClose} aria-label="close">
              <S.ToolbarIcon>
                <MdClose />
              </S.ToolbarIcon>
            </IconButton>
          </Toolbar>
        </S.AppBarModal>
        {children}
      </Dialog>
    </S.Wrapper>
  );
};

FullScreenDialog.propTypes = {
  children: PropTypes.object.isRequired,
  btnText: PropTypes.string.isRequired,
  type: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipOn: PropTypes.bool
};

export default FullScreenDialog;
