import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import Skeleton from '@material-ui/lab/Skeleton';

export const Wrapper = styled.div`
  margin-top: 2rem;
`;

export const GroupContent = styled.div``;

export const Content = styled.div`
  display: grid;
  ${({ theme }) => css`
    gap: ${theme.spacings.small};
  `}
  width: 80vw;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);

  ${media.lessThan('960px')`
    grid-template-columns: repeat(2, 20rem);
    grid-template-rows: repeat(5, 1fr);

    ${({ theme }) => css`
      gap: ${theme.spacings.xxsmall};
    `}
  `}

  ${media.lessThan('600px')`
    grid-template-columns: 1fr;

    ${({ theme }) => css`
      gap: ${theme.spacings.xxsmall};
    `}
  `}
`;

export const SkeletonTitle = styled(Skeleton)`
  width: 45%;
  margin-bottom: 2rem !important;
`;

export const SkeletonLabel = styled(Skeleton)`
  width: 100%;
`;

export const SkeletonInput = styled(Skeleton)`
  max-width: 16rem !important;
  max-height: 1rem !important;
`;

export const SkeletonButton = styled(Skeleton)`
  width: 100%;
  margin-top: 1.6rem !important;
`;
