import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import {
  formatCurrency,
  formatCpf,
  formatCnpj,
  formatRegistrationNumber
} from 'utils/formatMask';

import { Grid } from '@material-ui/core';

import * as S from './styles';

const CardSearch = ({ data, viewClick }) => {
  const masks = useMemo(() => {
    const { document, registration_number, debt_balance } = data;
    return {
      currency: formatCurrency(debt_balance),
      document:
        String(document).length == 11
          ? formatCpf(document)
          : formatCnpj(document),
      registrationNumber: formatRegistrationNumber(registration_number)
    };
  }, [data]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Card>
          <Grid container>
            <Grid container justifyContent="space-between">
              <S.Grid item xs={11} sm={5} md={2} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Nº Inscrição:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {masks.registrationNumber}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={2} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Auto de Infração:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {String(data.infraction_notice) == '0' ||
                  !data.infraction_notice
                    ? '-'
                    : data.infraction_notice}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={3} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Fase atual:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {data.current_step}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={5} md={4} lg={2}>
                <S.TypographyLabel variant="subtitle1">
                  Saldo Devedor:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {formatCurrency(data.debt_balance)}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={11} md={1} lg={1}>
                <S.Button
                  type="submit"
                  onClick={() => {
                    viewClick(data);
                  }}
                >
                  {'Ver'}
                </S.Button>
              </S.Grid>
            </Grid>
          </Grid>
        </S.Card>
      </S.Content>
    </S.Wrapper>
  );
};

CardSearch.propTypes = {
  data: PropTypes.object,
  viewClick: PropTypes.func
};

export default memo(CardSearch);
