import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { AuthProvider } from 'contexts/auth';
import { SnackbarProvider } from 'contexts/snackbar';

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
