import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import Moment from 'moment';
import 'moment/locale/pt-br';
import { Grid } from '@material-ui/core';

import * as S from './styles';

const CardValidate = ({ data, code }) => {
  Moment.locale('pt-br');
  const masks = useMemo(() => {
    const { document, registration_number } = data;
    return {
      document: document,
      registrationNumber: registration_number
    };
  }, [data]);
  return (
    <S.Wrapper>
      <S.Content>
        <S.Card>
          <Grid container>
            <Grid container justifyContent="space-between">
              <S.Grid item xs={11} sm={6} md={6} lg={6}>
                <S.TypographyLabel variant="subtitle1">
                  Código de Validação
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">{code}</S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={6} md={6} lg={6}>
                <S.TypographyLabel variant="subtitle1">
                  Data de emissão:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {data.date_issue}
                </S.TypographyValue>
              </S.Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.Grid item xs={11} sm={6} md={6} lg={6}>
                <S.TypographyLabel variant="subtitle1">
                  Nº CDA:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {masks.registrationNumber}
                </S.TypographyValue>
              </S.Grid>
              <S.Grid item xs={11} sm={6} md={6} lg={6}>
                <S.TypographyLabel variant="subtitle1">
                  {data.document_type}:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {masks.document}
                </S.TypographyValue>
              </S.Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item xs={11} sm={10} md={11} lg={9}>
                <S.TypographyLabel variant="subtitle1">
                  Situação da Dívida:
                </S.TypographyLabel>
                <S.TypographyValue variant="body2">
                  {data.status}
                </S.TypographyValue>
              </Grid>
            </Grid>
          </Grid>
        </S.Card>
      </S.Content>
    </S.Wrapper>
  );
};

CardValidate.propTypes = {
  data: PropTypes.object,
  showMessage: PropTypes.bool,
  code: PropTypes.string
};

export default memo(CardValidate);
