const alertState = {
  isAlert: false,
  severity: 'success',
  message: 'default'
};

const alertReducer = (state, { type, payload }) => {
  switch (type) {
    case 'CLOSE_ALERT':
      return {
        ...state,
        isAlert: false
      };
    case 'SHOW_ALERT':
      return {
        isAlert: true,
        message: payload.message,
        severity: payload.severity
      };
    default:
      throw new Error();
  }
};

export { alertState, alertReducer };
