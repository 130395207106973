import FormSearch from 'components/FormSearch';
import CardInfo from 'components/CardInfo';
import CardAlert from 'components/CardAlert';
import * as S from './styles';
import { Pagination } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import usePagination from '../Search/Pagination';
import { useAlert } from 'hooks/use-alert';
import { useFormik } from 'formik';
import Dialog from 'components/Dialog';
import { useNotificationCda } from 'hooks/use-notification/useNotification';
import CardView from 'components/CardView';
import { certificateValidateSchema } from 'utils/validations';
import { MdBorderColor } from 'react-icons/md';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CircularProgress } from '@material-ui/core';
import { useCertificate } from 'hooks/use-certificate';
import SkeletonLoading from 'components/SkeletonLoading/Search';

const RegisterAlert = () => {
  let [page, setPage] = useState(1);
  const {
    getAlert,
    loading,
    setCdaList,
    cdaList,
    newSearch,
    setNewSearch,
    data
  } = useAlert();

  const { removeNotificationCda } = useNotificationCda(data);
  const { createCertificate, loadingCertificate } = useCertificate();

  let [clicked, setClicked] = useState(false);
  let [cdaClicked, setCdaClicked] = useState(null);

  const viewClick = (info) => {
    setClicked(true);
    setCdaClicked(info);
  };

  const PER_PAGE = 10;
  const CDA_LIST_SIZE = typeof cdaList !== 'undefined' ? cdaList.length : 1;
  const count = Math.ceil(CDA_LIST_SIZE / PER_PAGE);
  const _DATA = usePagination(cdaList, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    if (newSearch) {
      setPage(1);
      _DATA.jump(1);

      setNewSearch(false);
    }
  }, [newSearch, page, _DATA, setNewSearch]);

  const formik = useFormik({
    initialValues: { cda: '', ai: '', document: '', processNumber: '' },
    validationSchema: certificateValidateSchema,
    onSubmit: async ({ cda, ai, document, processNumber }) => {
      setClicked(false);
      setCdaList([]);
      await getAlert(cda, ai, document);
      let certificateDate = {
        registration_number: 0,
        isTributary: false
      };

      if (!loading && cdaList.length == 1) {
        certificateDate = cdaList[0];
      } else {
        certificateDate = cdaClicked;
      }

      await createCertificate(
        certificateDate.registration_number,
        processNumber,
        certificateDate.is_tributary
      );
      formik.resetForm();
    }
  });

  return (
    <>
      <CardInfo
        gender="she"
        text="Aqui você gerencia as incrições com alerta de alteração de situação."
      />
      <FormSearch
        setClicked={setClicked}
        setCdaList={setCdaList}
        getSearch={getAlert}
        loading={loading}
      />
      {loading && <SkeletonLoading />}
      {cdaList.length == 0 && !loading && (
        <S.ResultNoCDA>
          Você não possui alertas de atualizações cadastrados para CDAs.
        </S.ResultNoCDA>
      )}
      {data.length != 0 && !loading && (
        <>
          {_DATA.currentData().map((cda) => {
            return (
              <>
                {!loading && !clicked && (
                  <CardAlert viewClick={viewClick} key={cda.key} data={cda} />
                )}
              </>
            );
          })}
        </>
      )}
      <>
        {cdaList.length > 0 && clicked && (
          <>
            <CardView
              data={cdaClicked}
              removeNotificationCda={removeNotificationCda}
            />
            <S.CardActions>
              <Dialog btnText="GERAR CERTIDÃO">
                <S.DialogContainer>
                  <S.DialogTitle>
                    Informar número de processo relacionado a essa requisição.
                  </S.DialogTitle>
                  <S.DialogForm onSubmit={formik.handleSubmit}>
                    <S.DialogInput
                      value={formik.values.processNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.processNumber &&
                        !!formik.errors.processNumber
                      }
                      helperText={
                        formik.touched.processNumber &&
                        formik.errors.processNumber
                      }
                      name="processNumber"
                      id="processNumber"
                      placeholder="Número do Processo"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {<MdBorderColor />}
                          </InputAdornment>
                        )
                      }}
                    />
                    <S.DialogSubmit type="submit">
                      {loadingCertificate ? (
                        <CircularProgress size={24} />
                      ) : (
                        'GERAR CERTIDÃO'
                      )}
                    </S.DialogSubmit>
                  </S.DialogForm>
                </S.DialogContainer>
              </Dialog>
              {data.data}
            </S.CardActions>
          </>
        )}
        {!loading && cdaList.length > 0 && !clicked && (
          <S.ContentPaginate>
            <S.TotalRegisters style={{ fontWeight: '300', color: '#575757' }}>
              {cdaList.length}
              {cdaList.length === 1 ? ' Inscrição' : ' Inscrições'}
            </S.TotalRegisters>
            <S.Paginate>
              <Pagination
                count={count}
                size="large"
                page={page}
                shape="rounded"
                onChange={handleChange}
              />
            </S.Paginate>
          </S.ContentPaginate>
        )}
      </>
    </>
  );
};
export default RegisterAlert;
