import { useCallback, useState } from 'react';
import { forgotPassword } from 'services/service';
import { useSnackbar } from 'hooks/useSnackbar';

export function useForgotPassword() {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_, alertDispatch] = useSnackbar();

  const sendForgotPassword = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const response = await forgotPassword(data);
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: response.data.message
          }
        });
      } catch (e) {
        setLoading(false);
        alertDispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: 'Nenhum cadastro foi encontrado com o CPF informado!',
            severity: 'error'
          }
        });
      } finally {
        setLoading(false);
      }
    },
    [alertDispatch]
  );
  return { sendForgotPassword, loading };
}
