import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { TextField as input, Button as btn } from '@material-ui/core';

export const FormWrapper = styled.div`
  width: 100%;
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
  `}
  > form {
    display: flex;
    justify-content: space-between;
    ${media.lessThan('1024px')`
      display: flex;
      flex-direction: column;
    `}
  }
`;

export const TextField = styled(input)`
  width: 80%;

  ${({ theme }) => css`
    margin-right: ${theme.spacings.xxsmall} !important;
  `}

  ${media.lessThan('1024px')`
    width: 100%;
  `}
`;

export const Button = styled(btn)`
  width: 80%;
  max-height: 2.5rem;
  ${({ theme }) => css`
    background: ${theme.colors.mpColor} !important;
    color: ${theme.colors.white} !important;
  `}

  ${media.lessThan('1024px')`
  height: 2.5rem;
  font-size: 1rem !important;
    width: 100%;
    ${({ theme }) => css`
      margin-top: ${theme.spacings.xxsmall} !important;
    `}
  `}
  ${media.between('1025px', '1366px')`
    font-size: .7rem !important;
  `}
`;
