import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import {
  Card as CardWrapper,
  Grid as GridWrapper,
  Typography as Label,
  Typography as Value
} from '@material-ui/core';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Name = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.font.light};
    margin-bottom: ${theme.spacings.xxsmall};

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xsmall};
    `}
  `}
`;
export const Content = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
  `}
`;

export const Card = styled(CardWrapper)`
  display: flex;
  justify-content: space-between !important;

  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
  `}

  ${media.lessThan('600px')`
    padding: 0.5rem;
  `}
`;
export const Grid = styled(GridWrapper)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall} !important;

    ${media.lessThan('600px')`
      margin-bottom: ${theme.spacings.xxsmall} !important;
    `}
  `}
`;

export const TypographyLabel = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.black} !important;
    font-family: ${theme.font.family} !important;
    font-weight: ${theme.font.bolder} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;
export const TypographyValue = styled(Value)`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray} !important;
    font-family: ${theme.font.family} !important;

    ${media.lessThan('600px')`
      font-size: ${theme.font.sizes.xxsmall} !important;
    `}
  `}
`;
